<template>
  <tr :class="getClassRow" :id="getRowId">
    <campaign-grid-cell
        v-model="mediaData.columns.asset"
        header="asset"
        type="file"
        view-mode="cell-label"
        class="campaign-grid-cell"
        :id="getAssetCellId"
        :error="isErrorInput"
        :path-route="'name'"
        @click="showToastNotification()"
    />
    <campaign-grid-cell
        v-model="mediaData.columns.sku"
        header="sku"
        type="text"
        view-mode="cell-input"
        transform="uppercase"
        :id="getSKUCellId"
        :editable="isEditable"
        :disabled="isDisabledInput"
        :max-content-size="10"
        :checker="isSKUValid"
        @cell-changed="changeRowHandle"
    />
    <campaign-grid-cell
        v-model="mediaData.columns.casemark"
        header="casemark"
        type="text"
        view-mode="cell-input"
        :id="getCasemarkCellId"
        :editable="isEditable"
        :disabled="isDisabledInput"
        @cell-changed="changeRowHandle"
    />
    <campaign-grid-cell
        v-model="mediaData.columns.color"
        header="color"
        type="text"
        view-mode="cell-input"
        :id="getColorCellId"
        :editable="isEditable"
        :disabled="isDisabledInput"
        @cell-changed="changeRowHandle"
    />
    <campaign-grid-cell
        v-model="mediaData.columns.size"
        header="size"
        type="text"
        view-mode="cell-input"
        :id="getSizeCellId"
        :editable="isEditable"
        :disabled="isDisabledInput"
        @cell-changed="changeRowHandle"
    />
    <campaign-grid-cell
        v-model="mediaData.columns.photographer"
        header="photographer"
        type="text"
        view-mode="cell-input"
        transform="titlecase"
        :id="getPhotographerCellId"
        :editable="isEditable"
        :disabled="isDisabledInput"
        @cell-changed="changeRowHandle"
    />
    <campaign-grid-cell header="actions" type="button">
      <template #buttons>
        <cdr-button
            type="button"
            modifier="sale"
            class="g-row-cell-button"
            :disabled="mediaData.loading"
            @click="deleteRowHandle(mediaData)">
            <template #icon-left>
              <font-awesome-icon
                :id="getRowIdIcon"
                :icon="getAwesomeIcon"
                :data-delete-loading="!mediaData.loading"
                :class="{ 'spin-image': mediaData.loading }"
              />
            </template>
          </cdr-button>
          <div class="campaign-grid-row-toast">
            <campaign-notification-toast ref="rowNotificationToast" />
          </div>
      </template>
    </campaign-grid-cell>
  </tr>
</template>

<script>
import {ref, toRef, computed, watch, onMounted, onUnmounted, onRenderTriggered} from 'vue';
import {CdrButton, IconTrash} from '@rei/cedar';
import Util from '../../../util/util';
import CampaignGridCell from './CampaignGridCell.vue';
import CampaignNotificationToast from '../notification/CampaignNotificationToast.vue';

export default {
  name:       'campaign-grid-row',
  components: {
    CdrButton,
    IconTrash,
    CampaignGridCell,
    CampaignNotificationToast,
  },
  props:      {
    mediaData: {
      type:     Object,
      required: true,
    },
    rowIndex:  {
      type:     Number,
      required: true,
    },
    id:        {
      type:     String,
      required: false,
    },
    classRow:  {
      type:     String,
      required: false,
    },
    editable:  {
      type:     Boolean,
      required: false,
      default:  true,
    },
  },
  setup ( props, { emit } ) {
    const rowNotificationToast = ref( null );
    const edit = ref( 'on' );
    const mediaData = toRef(props, 'mediaData');
    const scrollYOffset = ref(window.scrollY);
    const scrollXOffset = ref(window.scrollX);

    const getClassRow = computed( () => {
      const oddClass = (props.rowIndex % 2) == 0 ? '' : ' odd ';
      return ('campaign-grid-row ' + oddClass + (props.classRow ?? '')).trim();
    } );

    const getRowId = computed( () => {
      return props.id;
    } );

    const getRowIdIcon = computed( () => {
      return props.id? (props.id + '-button-icon'): '';
    } );

    const getAssetCellId = computed( () => {
      return props.id? (props.id + '-image-cell'): '';
    } );

    const getSKUCellId = computed( () => {
      return props.id? (props.id + '-sku-cell'): '';
    } );

    const getCasemarkCellId = computed( () => {
      return props.id? (props.id + '-casemark-cell'): '';
    } );

    const getColorCellId = computed( () => {
      return props.id? (props.id + '-color-cell'): '';
    } );

    const getSizeCellId = computed( () => {
      return props.id? (props.id + '-size-cell'): '';
    } );

    const getPhotographerCellId = computed( () => {
      return props.id? (props.id + '-photographer-cell'): '';
    } );

    const isEditable = computed( () => {
      return edit.value === 'on';
    } );

    const isDisabledInput = computed( () => {
      return mediaData.value.loading || !mediaData.value.uploaded;
    } );

    const isErrorInput = computed( () => {
      return !Util.isNullValue(mediaData.value.error);
    } );

    const getAwesomeIcon = computed(() => mediaData.value.loading ? "sync" : "trash-alt");

    const changeRowHandle = ( event ) => {
      const { header, valid, value } = event;
      if ( valid ) {
        const info = mediaData.value;
        const columns = info.columns;
        mediaData.value.columns = {
          ...columns,
          [ header ]: value,
        };
        emit( 'rowChanged', {
          index: props.rowIndex,
          data: mediaData.value,
        } );
      }
    };

    const isSKUValid = (value) => /^[a-zA-Z0-9]+$/.test(value);

    const setEditable = ( value ) => {
      edit.value = (value) ? 'on' : 'off';
    };

    const deleteRowHandle = ( data ) => {
      const rowData = { index: props.rowIndex, data };
      emit( 'rowDeleted', rowData );
    };

    const handleScroll = () => {
      scrollYOffset.value = window.scrollY;
      scrollXOffset.value = window.scrollX;
    };

    const showToastNotification = () => {
      if (!mediaData.value.uploaded) {
        rowNotificationToast.value.displayToast(mediaData.value.error);
      }
    };

    watch(mediaData, (newVal) => {
      mediaData.value = newVal;
    });

    onRenderTriggered( () => {
      setEditable( props.editable );
    } );

    onMounted( () => {
      window.addEventListener('scroll', handleScroll);
    } );

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      rowNotificationToast,
      scrollYOffset,
      scrollXOffset,
      mediaData,
      edit,
      getClassRow,
      getRowId,
      getRowIdIcon,
      getAssetCellId,
      getSKUCellId,
      getCasemarkCellId,
      getColorCellId,
      getSizeCellId,
      getPhotographerCellId,
      getAwesomeIcon,
      isDisabledInput,
      isEditable,
      isErrorInput,
      deleteRowHandle,
      changeRowHandle,
      showToastNotification,
      isSKUValid,
      setEditable,
    };
  },
}
</script>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/docsite/scss/cdr-tokens.scss";
@import "@rei/cdr-component-variables/dist/scss/index.scss";

.campaign-grid-cell {
  max-width: 230px;
  width: max-content;
  word-wrap: break-word;
}

.campaign-grid-row-toast {
  position: absolute;
  top: calc(1vh + var(--scroll-y-offset));
  right: calc(1vw + var(--scroll-x-offset));
}

.odd {
  background-color: $cdr-color-background-table-row-alt;
}

.campaign-grid-row > td {
  padding: $cdr-space-eighth-x $cdr-space-eighth-x !important;
}

.g-row-cell-button {
  display: flex;
  align-items: center;
}
</style>