const checkSuccessResponse = (response) => {
  const classResponse = Math.floor((response.status / 100) % 10);
  if (classResponse === 2) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject({
      status: response.status,
      data: response.data,
    });
  }
};

const checkErrorResponse = (error) => {
  const status = error.response?.status ?? 500;
  const data = error.response?.data ?? error.request ?? error.message ?? error;
  return Promise.reject({
    status,
    data,
  });
};

const getUploadType = (file) => {
  const fileName = file.name;
  const fileExtension = fileName
    .split(".")
    .pop()
    .toLowerCase();
  switch (fileExtension) {
    case "mp4":
    case "webm":
    case "ogg":
    case "mov":
    case "wmv":
    case "avi":
      return 9;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "psd":
    case "tiff":
    case "tif":
      return 7;
    case "ai":
    case "eps":
      return 8;
    default:
      return 6;
  }
};

export const uploadCampaignAsset = async ($http, token, resource) => {
  const jsonData = {
    campaignEntryId: resource.campaignEntryId,
    filename: resource.asset.name,
    type: getUploadType(resource.asset),
  };
  const formData = new FormData();
  formData.append("file", resource.asset);
  formData.append("uploadRequest", JSON.stringify(jsonData));
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "campaign-token": token,
    },
  };
  try {
    const response = await $http.post("rs/campaign/asset", formData, config);
    return checkSuccessResponse(response);
  } catch (error) {
    return checkErrorResponse(error);
  }
};

export const deleteCampaignAsset = async ($http, token, asset) => {
  const config = {
    headers: {
      "campaign-token": token,
    },
  };
  try {
    const url = `rs/campaign/${asset.campaignEntryId}/asset/${asset.campaignEntryAssetId}`;
    const response = await $http.delete(url, config);
    return checkSuccessResponse(response);
  } catch (error) {
    return checkErrorResponse(error);
  }
};

export const createCampaignVendor = async ($http, token, campaign) => {
  const config = {
    headers: {
      "campaign-token": token,
      "Content-Type": "application/json",
    },
  };
  try {
    const url = "rs/campaign";
    const response = await $http.post(url, campaign, config);
    return checkSuccessResponse(response);
  } catch (error) {
    return checkErrorResponse(error);
  }
};

export const getCampaignVendor = async ($http, token, campaignEntryId) => {
  const config = {
    headers: {
      "campaign-token": token,
      "Content-Type": "application/json",
    },
  };
  try {
    const url = `rs/campaign/entries/${campaignEntryId}`;
    const response = await $http.get(url, config);
    return checkSuccessResponse(response);
  } catch (error) {
    return checkErrorResponse(error);
  }
};
