import ResourceFilenames from "./ResourceFilenames";
import routeNames from "./routeNames";

export default [
    {
        q: "Portal Support",
        a: "Contact Us with any questions.",
        link: {
            title: "Contact Us",
            to: {name : routeNames.CONTACT}
        }
    },
    {
        q: "How do I find REI 10-character SKU?",
        a: "To look up an REI 10-character SKU, simply enter the REI 6-character style on the following page:",
        link: {
            title: "SKU Finder",
            to: {name : routeNames.SKU_FINDER}
        }
    },
    {
        q: "What are the requirements for Product Information copy/specs, Images, and Videos?",
        a: "Please refer to the REI Content Requirements:",
        externallink: {
            title: "REI Content Requirements & Style Guides",
            to: "https://public.cloud-dam.rei.com/api/public/content/VendorContentRequirements"
        }
    },
    {
        q: "What are the requirements for Sustainability?",
        a: "Before you make any claims about the environmental benefits or sustainability of your products in Product Information and Specs, <b>make sure you’re aligned with the Federal Trade Commission (FTC) guidelines</b>. Many claims are governed by the FTC and claims/products must meet certain standards. For info from the FTC on environmental claims, check out the FTC <a href='https://www.ftc.gov/system/files/documents/public_events/975753/ftc_-_environmental_claims_summary_of_the_green_guides.pdf'>Green Guides</a>. To read more about how to fill out the Sustainability specs accurately on REI Product Information templates, click below.",
        externallink: {
            title: "REI Product Sustainability Specs Guidance",
            to:    "https://www.rei.com/dam/19783135_rei-product-sustainability-specs-guidance.pdf"
        }
    },
    {
        q: "Why does some functionality on the site not work?",
        a: "We recommend using Chrome, Firefox, or Safari to take advantage of the features of this website. Internet Explorer is not supported. If you are using a recommended browser and are still having trouble, please ensure that you are using the most recent version of your browser."
    }
];