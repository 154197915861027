<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row download-controls">
                <div class="col-sm-10">
                    <div class="ui-header">
                        <h3 class="title">Download Assets</h3>
                        <img title="refresh" :class="{ 'reload-image': true, 'spin-image': loadingAssets }"
                        @click="fetchData" :src="reloadImg" width="22" height="17">
                    </div>
                    <p class="text-info">Download assets by category or by asset</p>
                </div>
                <div class="col-sm-2">
                    <input type="button" @click="downloadCategory" class="form-control admin-btn" value="Download"
                        :disabled="downloading" />
                </div>
            </div>

            <div class="row download-table">
                <div class="col-xs-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Select All</th>
                                <th>List Assets</th>
                                <th>Asset Category</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="cat in categories">
                                <tr :key="cat.name" v-bind:class="{ 'selected-row': cat.selected }"
                                    v-if="cat.assets.length">
                                    <td><input @click="selectRow(cat)" type="radio" name="type-select"
                                            :disabled="downloading" /></td>
                                    <td><input @click="selectSingle(cat)" type="radio" name="single-select"
                                            :disabled="downloading" /></td>
                                    <td class="asset-type">{{ cat.name }}</td>
                                    <td>{{ cat.assetTotal }}</td>
                                    <td><img :class="{ 'reload-image': true, 'spin-image': cat.downloading, 'display-image': !cat.downloading }"
                                            :src="reloadImg" width="22" height="17">
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="singleAssetList.length" class="row download-table single-table">
                <div class="col-xs-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Upload Date</th>
                                <th>Brand</th>
                                <th>Notes</th>
                                <th>Filename - Click to Download</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="asset in singleAssetListFormatted" :key="asset.id">
                                <td class="download-date">{{ asset.uploadDto.uploadDate }}</td>
                                <td>{{ asset.uploadDto.brand }}</td>
                                <td>{{ asset.uploadDto.notes }}</td>
                                <td class="download-file" @click="downloadSingle(asset)">{{ asset.uploadDto.filename }}
                                </td>
                                <td><img :class="{ 'reload-image': true, 'spin-image': asset.downloading, 'display-image': !asset.downloading }"
                                        :src="reloadImg" width="22" height="17">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import saveFile from "../../util/saveFile";
import reloadImg from "../../../resources/static/img/reload.png";
import formatDate from "../../util/formatDate";
import Util from "../../util/util";
import Config from "../../data/AdminConfig";
import { useAdminApi } from "../../composables/admin";

export default {
    name: "download-assets",
    setup() {
        const { getAssets, deleteAsset, downloadAsset, getAssetDownloadedBy } = useAdminApi();
        const $store = useStore();
        const $router = useRouter();
        const categories = ref({});
        const selected = ref(null);
        const singleAssetCategory = ref(null);
        const loadingAssets = ref(false);
        const singleAssetList = ref([]);
        const downloadIndex = ref(0);
        const downloading = ref(false);

        const singleAssetListFormatted = computed(() => singleAssetList.value.map((asset) => {
            const uploadDto = {
                ...asset.uploadDto,
                uploadDate: formatDate(asset.uploadDto.uploadDate),
            };
            return {
                ...asset,
                uploadDto,
            };
        }));

        const selectRow = (category) => {
            selected.value = category;
            //remove any selected rows
            for (let cat in categories.value) {
                categories.value[cat].selected = false;
            }
            //set selected for css styling
            category.selected = true;
        };

        const selectSingle = (category) => {
            singleAssetCategory.value = category;
            singleAssetList.value = category.assets;
        };

        const addMessage = (message) => {
            $store.commit("addMessage", message);
        };

        const nextCategoryDownload = () => {
            downloadIndex.value = downloadIndex.value + 1;
            // small timeout is needed to not occasionally overwrite a download
            setTimeout(downloadCategory, 500);
        };

        const fetchData = async () => {
            loadingAssets.value = true;
            try {
                const items = await getAssets();
                const keys = Object.keys(items);
                if (Util.isEmptyArray(keys)) {
                    addMessage("No new assets");
                } else {
                    categories.value = items;
                }
                loadingAssets.value = false;
            } catch (error) {
                if (error.response.status === 401) {
                    //redundant auth check, non admin's shouldn't be able to get here
                    //but just in case redirect to login page
                    $router.push("/");
                } else {
                    addMessage("Error fetching uploaded assets");
                    loadingAssets.value = false;
                }
            }
        };

        const clearDownloaded = () => {
            const currentAssets = selected.value.assets
                .filter(asset => asset.status !== Config.DOWNLOAD_STATUS.DOWNLOADED);
            selected.value.assets = currentAssets;
            selectSingle(selected.value);
        };

        const archive = (asset) => {
            return deleteAsset(asset)
                .then(_res => { })
                .catch(_error => { });
        };

        const skipSingleAssetList = (asset) => {
            const currentAssets = singleAssetList.value
                .filter((assetItem) => assetItem.id !== asset.id);
            singleAssetList.value = currentAssets;
            singleAssetCategory.value.assetTotal--;
        };

        const downloadSingle = async (asset) => {
            if (asset.downloading) {
                return Promise.resolve(true);
            }
            asset.downloading = true;
            try {
                const result = await downloadAsset(asset);
                saveFile(result.data, result.filename);
                asset.downloading = false;
                skipSingleAssetList(asset);
                await archive(asset);
                return true;
            } catch (error) {
                asset.downloading = false;
                addMessage(error.message);
                return false;
            }
        };

        const downloadAssetCategory = async (asset) => {
            try {
                const result = await downloadAsset(asset);
                saveFile(result.data, result.filename);
                await archive(asset);
                asset.status = Config.DOWNLOAD_STATUS.DOWNLOADED;
            } catch (error) {
                let message = error.message;
                const username = $store.state.username;
                const response = await getAssetDownloadedBy({ ...asset, username });
                if (!Util.isEmpty(response.data) && username !== response.data) {
                    message += " Already downloaded by: " + response.data;
                    //asset not found due to other user downloading at same time
                    //remove asset from category list
                    asset.status = response.status;
                }
                throw {
                    ...error,
                    ...response,
                    message,
                };
            }
        };

        const downloadCategory = () => {
            const downloadIdx = downloadIndex.value;
            if (!selected.value) {
                addMessage("Please select a category");
                return Promise.resolve();
            }

            const downloadedAll = downloadIdx === selected.value.assets.length;

            if (downloadedAll) {
                downloading.value = false;
                downloadIndex.value = 0;
                clearDownloaded();
                return Promise.resolve();
            } else {
                const asset = selected.value.assets[downloadIdx];
                downloading.value = true;
                selected.value.downloading = true;
                return downloadAssetCategory(asset).then(() => {
                    asset.status = Config.DOWNLOAD_STATUS.DOWNLOADED;
                    selected.value.assetTotal--;
                    nextCategoryDownload();
                }).catch((error) => {
                    asset.status = Config.DOWNLOAD_STATUS.FAILED;
                    selected.value.downloading = false;
                    let message = error.message;
                    if (!Util.isEmpty(message)) {
                        message = " " + message;
                        //asset not found due to other user downloading at same time
                        //remove asset from category list
                        asset.status = error.status;
                        selected.value.assetTotal--;
                    }
                    addMessage(message);
                    nextCategoryDownload();
                });
            }
        };

        onMounted(() => {
            fetchData();
        });

        return {
            reloadImg,
            categories,
            selected,
            singleAssetCategory,
            loadingAssets,
            singleAssetList,
            downloadIndex,
            downloading,
            singleAssetListFormatted,
            selectRow,
            selectSingle,
            addMessage,
            fetchData,
            archive,
            downloadSingle,
            downloadCategory,
            clearDownloaded,
            nextCategoryDownload,
        };
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/base/mixins";
@import "../../../scss/base/vars";

.admin-panel {
    min-height: 600px;

    .download-controls {

        margin-bottom: 10px;

        .reload-image {
            margin-top: 20px;
            float: right;
        }

        .admin-btn {
            float: right;
        }
    }

    table {

        thead {
            background-color: $mainOffsetColor;
            font-weight: bold;

            th {
                height: 50px;
            }
        }

        tbody {
            background-color: white;

            tr {
                border-left: 1px solid $mainBgColor;
                border-right: 1px solid $mainBgColor;

                td {
                    padding-top: 10px;
                    padding-bottom: 10px;

                    input[type="radio"] {
                        margin-top: 0;
                        padding-bottom: 5px;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }

            .download-date {
                min-width: 210px;
            }

            .download-file {

                color: $adminBlue;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .selected-row {
                border-left: 1px solid $adminBlue;
                border-right: 1px solid $adminBlue;
            }

            .selected-row td {
                background-color: $adminLightBlue;
                border-bottom: 1px solid $adminBlue;
                border-top: 1px solid $adminBlue;
            }

            .selected-row:hover td {
                background-color: $adminLightBlue;
            }

            .asset-type {
                color: $adminBlue;
                padding-top: 14px;

            }
        }
    }
}

.single-table {
    height: auto;
    max-height: 500px;
    overflow: auto;
}
</style>