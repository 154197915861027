<template>
  <td :header="getHeaderLabel" :class="getClassCell">
    <div v-if="isInputShown" :id="getContainerId" :class="getClassCellContent">
      <cdr-text
        v-if="isCellLabelMode"
        :class="getClassCellContentLabel"
        :id="getLabelId"
      >
        {{ getLabelData }}
      </cdr-text>
      <input
        v-if="!isCellLabelMode"
        v-bind:value="modelValue"
        ref="textbox"
        :disabled="disabled"
        :type="getInputType"
        :id="getInputId"
        :name="getInputName"
        :class="getClassCellContentInput"
        :maxlength="maxContentSize"
        @blur="onBlurInput"
        @input="onInputData"
      />
    </div>
    <div v-else :class="getClassCellButtons">
      <slot name="buttons" />
    </div>
  </td>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { CdrText, CdrInput } from "@rei/cedar";
import * as lodash from "lodash";
import util from "../../../util/util";

export default {
  name: "campaign-grid-cell",
  components: {
    CdrText,
    CdrInput,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Object, String],
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    pathRoute: {
      type: String,
      required: false,
    },
    viewMode: {
      type: String,
      required: false,
      default: "cell-input",
      validator(value) {
        return ["cell-input", "cell-label"].includes(value);
      },
    },
    classCell: {
      type: String,
      required: false,
    },
    classCellContent: {
      type: String,
      required: false,
    },
    classCellContentLabel: {
      type: String,
      required: false,
    },
    classCellContentInput: {
      type: String,
      required: false,
    },
    classCellButtons: {
      type: String,
      required: false,
    },
    forceFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    transform: {
      type: [String, Function],
      required: false,
      validator(value) {
        if (lodash.isFunction(value)) return true;
        const cases = ["uppercase", "lowercase", "sentencecase", "titlecase"];
        return lodash.isString(value) && cases.includes(value);
      },
    },
    checker: {
      type: Function,
      required: false,
    },
    maxContentSize: {
      type: Number,
      required: false,
    },
  },
  setup(props, { emit }) {
    const textbox = ref(null);
    const isValidContent = ref(true);
    const fnChecker = props.checker;

    const applyTransformData = (value) => {
      const callback = props.transform;
      if (!callback) return value;
      if (lodash.isFunction(callback)) return callback(value);
      return util.transformStringToCase(value, callback);
    };

    const isValidRequiredField = (value) => {
      return props.required ? !util.isEmpty(value) : true;
    };

    const onInputData = ($event) => {
      const value = applyTransformData($event.target.value);
      isValidContent.value = isValidRequiredField(value);
      if (
        fnChecker !== null &&
        fnChecker !== undefined &&
        isValidContent.value
      ) {
        isValidContent.value = fnChecker(
          typeof value === "string" ? value.trim() : value
        );
      }
      emit("update:modelValue", value);
      emit("cellChanged", {
        header: props.header,
        valid: isValidContent.value,
        value,
      });
    };

    const onBlurInput = () => {
      if (props.editable) return;
      emit("cellBlur", textbox.value);
    };

    const onFocusInput = async () => {
      textbox.value.focus();
      emit("cellFocus", textbox.value);
      await util.delay(1);
    };

    const getClassCell = computed(() => {
      return props.classCell;
    });

    const getClassCellContent = computed(() => {
      return ("cell-container " + (props.classCellContent ?? "")).trim();
    });

    const getClassCellContentLabel = computed(() => {
      const mainClass = props.error ? "g-label-error " : "g-label ";
      return (mainClass + (props.classCellContentLabel ?? "")).trim();
    });

    const getClassCellContentInput = computed(() => {
      const mainClass = isValidContent.value ? "g-input " : "g-input-error ";
      return (mainClass + (props.classCellContentLabel ?? "")).trim();
    });

    const getClassCellButtons = computed(() => {
      return ("g-buttons " + (props.classCellButtons ?? "")).trim();
    });

    const getHeaderLabel = computed(() => {
      return props.header;
    });

    const getContainerId = computed(() => {
      return props.id ? props.id + "-content" : "";
    });

    const getInputId = computed(() => {
      return props.id ? props.id + "-input" : "";
    });

    const getLabelId = computed(() => {
      return props.id ? props.id + "-label" : "";
    });

    const getInputName = computed(() => {
      return props.id ? props.id + "-name" : "";
    });

    const getLabelData = computed(() => {
      const path = props.pathRoute;
      if (!path) return;
      const label = path && lodash.get(props.modelValue, path);
      return label || props.modelValue;
    });

    const getInputType = computed(() => {
      return props.type || "text";
    });

    const isCellLabelMode = computed(() => {
      return props.viewMode === "cell-label" || !props.editable;
    });

    const isInputShown = computed(() => {
      return props.type !== "button";
    });

    onMounted(() => {
      if (props.forceFocus) {
        onFocusInput();
      }
    });

    return {
      textbox,
      isValidContent,
      applyTransformData,
      onInputData,
      onBlurInput,
      onFocusInput,
      getClassCell,
      getClassCellContent,
      getClassCellContentLabel,
      getClassCellContentInput,
      getClassCellButtons,
      getHeaderLabel,
      getContainerId,
      getInputId,
      getLabelId,
      getInputName,
      getLabelData,
      getInputType,
      isCellLabelMode,
      isInputShown,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/docsite/scss/cdr-tokens.scss";
@import "@rei/cdr-component-variables/dist/scss/index.scss";

.cell-container {
  width: 100%;
}

.cell-container > .g-label,
.cell-container > .g-label-error {
  @include cdr-text-heading-sans-200;
  padding: $cdr-space-one-x;  
}

.cell-container > .g-label-error {
  color: $cdr-color-text-error;
  cursor: pointer;
}

.cell-container > .g-input {
  @include cdr-input-base-mixin;
}

.cell-container > .g-input-error {
  @include cdr-input-base-mixin;
  @include cdr-input-error-mixin;
}

.cell-container > .g-buttons {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
}
</style>
