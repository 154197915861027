import { getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";
import * as lodash from "lodash";
import {
  uploadCampaignAsset,
  deleteCampaignAsset,
  createCampaignVendor,
  getCampaignVendor,
} from "../../helpers/campaignAsset";
import routeNames from "../../data/routeNames";
import campaignOptions from "../../data/CampaignOptions";
import Util from "../../util/util";

const fieldTypeParameter = "PARAMETER";
const unauthorizedStatusCode = 401;
const badRequestStatusCode = 400;
const notFoundStatusCode = 404;

export const useCampaignFormApi = () => {
  const instance = getCurrentInstance();
  const $http = instance?.appContext?.config.globalProperties.$http;
  const $properties = instance?.appContext?.config.globalProperties.$props;
  const router = useRouter();

  const getTokenFromSession = () => {
    const token = Cookies.get("campaign-token");
    if (Util.isEmpty(token)) {
      router.push({
        name: routeNames.CAMPAIGN_EXPIRED,
      });
      return null;
    }
    return token;
  };

  const checkErrorApi = (error) => {
    // if the status code is unauthorized...
    // should redirect to the campaign expired page...
    if (error.status === unauthorizedStatusCode || error.status === notFoundStatusCode) {
      router.push({
        name: routeNames.CAMPAIGN_EXPIRED,
      });
      throw undefined;
    } else {
      throw error;
    }
  };

  const uploadAssetApi = (assetInfo) => {
    const token = getTokenFromSession();
    if (Util.isEmpty(token)) {
      return Promise.reject(undefined);
    }
    return uploadCampaignAsset($http, token, assetInfo).catch((error) =>
      checkErrorApi(error)
    );
  };

  const deleteAssetApi = (asset) => {
    const token = getTokenFromSession();
    if (Util.isEmpty(token)) {
      return Promise.reject(undefined);
    }
    return deleteCampaignAsset($http, token, asset)
      .then(() => true)
      .catch((error) => checkErrorApi(error));
  };

  const saveDataToStorage = (data) => {
    const attrs = Object.keys(data);
    attrs.forEach((attr) => {
      sessionStorage.setItem(attr, data[attr]);
    });
  };

  const createCampaignApi = (campaignEntryId, formData) => {
    const token = getTokenFromSession();
    if (Util.isEmpty(token)) {
      return Promise.reject(undefined);
    }
    const ids = Object.keys(formData.assetMetadataList || {});
    const assetMetadataList = ids.map((id) => ({
      campaignEntryAssetId: formData.assetMetadataList[id].data,
      sku: formData.assetMetadataList[id].columns.sku,
      casemark: formData.assetMetadataList[id].columns.casemark,
      color: formData.assetMetadataList[id].columns.color,
      size: formData.assetMetadataList[id].columns.size,
      photographer: formData.assetMetadataList[id].columns.photographer,
    }));
    const usageMediaRights = campaignOptions.MEDIA_RIGHT.find(
      (option) => option.value === formData.usageMediaRights
    );
    const campaign = {
      campaignEntryId,
      assetMetadataList,
      name: formData.name,
      title: formData.title,
      email: formData.email,
      brand: formData.brand,
      usageMediaRights: usageMediaRights.label,
      usageRightsExclusivity: formData.usageRightsExclusivity === "yes",
      usageRightsTimePeriod: formData.usageRightsTimePeriod === "yes",
      usageRightsEmbargoDate: formData.usageRightsEmbargoDate,
      usageRightsEndDate: formData.usageRightsEndDate,
    };
    return createCampaignVendor($http, token, campaign)
      .then(() => {
        saveDataToStorage({
          name: formData.name,
          title: formData.title,
          email: formData.email,
          brand: formData.brand,
        });
        router.push({
          name: routeNames.CAMPAIGN_SUCCESS,
        });
        return true;
      })
      .catch((error) => checkErrorApi(error))
      .catch((error) => {
        if (!error) {
          throw error;
        } else if (error.status !== badRequestStatusCode) {
          throw error;
        } else {
          const { status, data } = error;
          const violations = data.parameterViolations;
          const errors = violations
            .filter(
              (violation) => violation.constraintType === fieldTypeParameter
            )
            .map(({ path, message, value }) => {
              return {
                message,
                value,
                path: path.replace("submitEntryForm.campaignEntryForm.", ""),
              };
            });
          throw { status, errors };
        }
      });
  };

  const getCampaignApi = (campaignEntryId) => {
    const token = getTokenFromSession();
    if (Util.isEmpty(token)) {
      return Promise.reject(undefined);
    }
    return getCampaignVendor($http, token, campaignEntryId)
      .catch((error) => checkErrorApi(error));
  };

  const getEnvironmentValue = (path) => {
    return lodash.get($properties, `appProps.${path}`);
  };

  return {
    uploadAssetApi,
    deleteAssetApi,
    createCampaignApi,
    getCampaignApi,
    getEnvironmentValue,
  };
};
