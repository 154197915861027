export default Object.freeze({
  GENERAL: [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ],
  MEDIA_RIGHT: [
    {
      value: "all-media",
      label: "All media",
    },
    {
      value: "digital-media-and-print",
      label: "Only digital media and print",
    },
    {
      value: "digital-media",
      label: "Only digital media",
    },
    {
      value: "print",
      label: "Only print",
    },
    {
      value: "for-product-page-display-only",
      label: "For product page and in-store display only",
    },
  ],
});
