<template>

    <div>
        <div class="row" v-if="config.uploadType === uploadType.LOGO">
            <div class="col-sm-10 col-sm-offset-1 logo-details">
                <h3>Requirements</h3>
                <ul>
                    <li>Format: vector-based Illustrator file .ai or .eps.</li>
                    <li>Flat, core logo without taglines, single color.</li>
                    <li>Two versions of the file. Solid 100% black and solid white logo.</li>
                </ul>
            </div>
        </div>
		<div class="row">
			<div class="col-sm-10 col-sm-offset-1">
				<h2>{{ config.title }}</h2>
			</div>
		</div>

		<div class="row vendor-details">
            <div class="col-sm-2 col-sm-offset-1">
                <input type="text" data-ui="name-field" v-model="name" class="form-control" v-bind:class="{ 'input-border' : !name }" placeholder="Your Name" >
            </div>
            <div class="col-sm-2">
                <input type="text" data-ui="email-field" v-model="email" class="form-control" v-bind:class="{ 'input-border' : !validEmail }" placeholder="Your Email Address">
            </div>
            <div class="col-sm-2" v-if="showBrand">
                <input type="text" data-ui="brand-field" v-model="brand" class="form-control" v-bind:class="{ 'input-border' : !brand }" placeholder="Brand">
            </div>
            <div v-bind:class="{ 'col-sm-2' : showBrand, 'col-sm-3' : !showBrand }">
                <input type="text" data-ui="notes-field" v-model="notes" class="form-control" placeholder="Notes">
            </div>

            <div v-bind:class="{ 'col-sm-2' : showBrand, 'col-sm-3' : !showBrand }">
                <input v-if="!dataNotEntered" type="file" data-ui="choose-files-button" multiple @change="processFiles($event.target.files)" class="form-control file-input">
            </div>

        </div>

        <!-- messages for the user triggered by certain events-->
        <link-message :type="'danger'" :display="displaySkuFinder" @close="displaySkuFinder = false">
            Need to lookup an REI SKU? Use the <router-link v-bind:to="{ name : ROUTE_NAMES.SKU_FINDER }" data-ui="sku-finder-link">SKU Finder</router-link>
        </link-message>
        <link-message :type="'info'" :display="displayVideoUploader" @close="displayVideoUploader = false">
            Have videos to upload for these products? Use the <a @click="navigateToVideoUploader">Video Uploader</a> after uploading images
        </link-message>

        <div data-ui="upload-info-rows" class="row">
            <!-- TODO IE -->
            <div class="col-sm-10 col-sm-offset-1" id="target"
                 @dragover.prevent="isDragging"
                 @dragend="notDragging"
                 @dragleave="notDragging"
                 @drop.prevent="processFiles($event.dataTransfer.files)"
                 :class="{ over : dragging }">

                <div v-for="file in files" class="file-details clearfix"
                     :class="{'alert-warning' : file.status === uploadStatus.ENTER_INFO,
                                    'alert-info' : file.status === uploadStatus.UPLOADING,
                                    'alert-success'  : file.status === uploadStatus.UPLOADED,
                                    'alert-danger'   : file.status === uploadStatus.FAILED}">
                    <div class="detail status">
                        <p>{{ file.status }}</p>
                    </div>
                    <div class="detail desc">
                        <p>{{ file.message }}</p>
                    </div>
                    <div class="detail filename">
                        <p>{{ file.file.name }}</p>
                    </div>

                    <div v-if="file.status === uploadStatus.ENTER_INFO" class="desc file-info-entry">
                        <input type="text" data-ui="styles-field" class="form-control file-info-text" placeholder="Enter list of REI 6-character styles" v-model="file.enteredProducts" :keyup="fileInfoProductKeyup(file)">
                        <input type="button" @click="fileInfoUpload(file)" data-ui="upload-button" class="form-control file-info-button" value="Upload">
                    </div>

                    <p v-if="file.status === uploadStatus.ENTER_INFO" v-for="product in file.fetchedProducts">{{ product.productDescription }}</p>
                </div>
                
                <h1 class="drop-text" v-if="dataNotEntered">Please enter your info above</h1>
                <h1 class="drop-text" v-else v-show="files.length === 0">Drop files here</h1>
                <!-- TODO IE Choose files above -->
                
                <div class="icon-container" v-if="config.uploadType === uploadType.VIDEO">
					<font-awesome-icon icon="video" size="10x" />
				</div>
				<div class="icon-container" v-if="config.uploadType === uploadType.IMAGE">
					<font-awesome-icon icon="camera" size="10x" />
				</div>
				<div class="icon-container" v-if="config.uploadType === uploadType.PI">
					<font-awesome-icon icon="table" size="10x" />
				</div>
                <div class="icon-container" v-if="config.uploadType === uploadType.LOGO">
					<font-awesome-icon :icon="['fab', 'pied-piper-alt']" size="10x" />
				</div>
                <div class="icon-container" v-if="config.uploadType === uploadType.PDF">
                    <font-awesome-icon icon="file-pdf" size="10x" />
                </div>

            </div>
        </div>

        <div class="row">
            <div data-ui="successful-uploads-text" class="col-sm-3 col-sm-offset-1 col-xs-6">
                <h3>Successful uploads: {{ successful }}</h3>
            </div>
            <div class="col-sm-3 col-sm-offset-1 col-xs-3">
                <input type="button" data-ui="clear-failed-button" v-if="failed" v-on:click="clearFailed" class="form-control failed-btn" value="Clear Failed">
            </div>
            <div class="col-sm-3 col-xs-3">
                <input type="button" data-ui = "clear-uploaded-button" v-if="uploaded" v-on:click="clearUploaded" class="form-control download-btn" value="Clear Uploaded">
            </div>
        </div>

        <div class="row" v-show="validEmail">
            <div class="col-sm-3 col-sm-offset-1 col-xs-6 request-upload">
                <input type="button" style="float: left" data-ui="request-uploads-button" v-on:click="requestUploadList" class="form-control download-btn" value="Request Confirmation"/>
                <p>Click this button to receive the list of everything<br/>you've uploaded in the past 30 days</p>
            </div>
            <div class="col-xs-1 col-xs-offset-1">
                <img title="reload" class="reload-image" :class="{'spin-image' : loading, 'reload-hide' : !loading }" :src="reloadImg"/>
            </div>
        </div>
    </div>

</template>

<script>
    import pLimit from 'p-limit';
    import LinkMessage from "./LinkMessage.vue";
    import PRODUCT_INFO from "../../data/ProductInfo";
    import UPLOAD_TYPE from "../../data/UploadTypes";
    import UPLOAD_STATUS from "../../data/UploadStatus";
    import UPLOAD_CONFIG from "../../data/UploadConfig";
    import ROUTE_NAMES from "../../data/routeNames";
    import Util from "../../util/util";
    import reloadImg from "../../../resources/static/img/reload.png";
    import normalizeFileName from "../../util/normalizeFileName";

    const limit = pLimit(2);
    export default {
        name: "upload",
        data() {
            return {
                reloadImg,
                brand: "",
                notes: "",
                files: [],
                successful: 0,
                loading: false,
                dragging: false,
                uploaded : false,
                failed : false,
                uploadStatus: UPLOAD_STATUS,
                uploadType: UPLOAD_TYPE,
                displaySkuFinder: false,
                displayVideoUploader: false,
                haveDisplayedVideoUploader: false,
                ROUTE_NAMES
            }
        },
        components: {
            LinkMessage
        },
        computed: {
            name: {
                get() {
                    return this.$store.state.name;
                },
                set(value) {
                    this.$store.commit("setName", value);
                }
            },
            email: {
                get() {
                    return this.$store.state.email;
                },
                set(value) {
                    this.$store.commit("setEmail", value);
                }
            },
            unauthorized() {
                return this.$store.state.unauthorized;
            },
            dataNotEntered(){
                //product uploader doesn't have brand entered by user
                return this.$store.state.name.trim() === "" || !this.validEmail
                        || (!this.config.productInfo === PRODUCT_INFO.BRAND && this.brand.trim() === "");

            },
            validEmail() {
                return Util.validateEmail(this.$store.state.email.trim());
            },
            config() {
                if (this.$route.params.type) {
                    return UPLOAD_CONFIG[this.$route.params.type];
                } else {
                    //stops console errors when navigating away from upload page
                    return {
                        title: ""
                    }
                }
            },
            showBrand() {
                return this.config.productInfo === PRODUCT_INFO.BRAND;
            }
        },
        methods: {
            isDragging() {
                this.dragging = true;
            },
            notDragging() {
                this.dragging = false;
            },
            async processFiles(files) {

                this.notDragging();

                if (this.dataNotEntered){
                    return;
                }

                const totalFiles = this.files?.length || 0;
                const assets = [...files];
                const config = this.config;
                const records = assets.sort((a, b) => a.size - b.size);
                // it's to preload the files that we want to upload
                for (let index = 0; index < records.length; index++) {
                    const indexFile = totalFiles + index;
                    this.files[indexFile] = {
                        styleId          : "",
                        file             : records[index],
                        status           : UPLOAD_STATUS.UPLOADING,
                        message          : "",
                        brand            : "",
                        enteredProducts  : "",
                        fetchedProducts  : [],
                        hasImage         : false,
                        outlet           : false,
                        error            : false
                    };
                }

                const pRecords = records.map(async (_e, index) => {
                    const uploadFiles = async (indexFile) => {
                        //check if the file type id correct for the upload
                        const fileExtension = this.getExtension(this.files[indexFile].file.name);
                        if (!config.accepted.includes(fileExtension)) {
                            this.setFailed(this.files[indexFile], "Incorrect file type. Expected: " + config.accepted.join(", "));
                            return true;
                        } else {
                            switch (config.productInfo) {
                                case PRODUCT_INFO.ENTER_INFO:
                                    //add file and let user add style
                                    this.files[indexFile].status = UPLOAD_STATUS.ENTER_INFO;
                                    this.files[indexFile].message = "Enter list of REI 6-character style";
                                    break;
                                case PRODUCT_INFO.BRAND:
                                    //vendor entered brand, no other requirements, just upload
                                    await this.upload(this.files[indexFile]);
                                    break;
                                default: //PRODUCT_INFO.SKU
                                    //fetch product data and verify product number is valid
                                    await this.addProductData(this.files[indexFile]);
                                    break;
                            }
                            return true;
                        }
                    };
                    const indexFile = totalFiles + index;
                    return limit(() => uploadFiles(indexFile));
                });
                await Promise.allSettled(pRecords);
            },
            async addProductData(data){

                //get sku
                let filename = data.file.name;

                if (filename.length > 10) {
                    const allowedChars = /^[a-zA-Z0-9]+$/;
                    let sku = filename.substring(0,10);
                    let isSku = n => allowedChars.test(n)
                    if (!isSku(sku)){
                        this.setFailed(data, "Filename does not start with 10-character SKU");
                        this.displaySkuFinder = true;
                        return;
                    }
                    try {
                        const response = await this.$http.get(`/rs/product/skuOrUpc/${sku}`);

                            let product = response.data;

                            if (typeof product.cleanDescription !== "undefined") {

                                data.styleId  = product.style;
                                data.message  = product.cleanDescription + " - " + product.color;
                                data.isOutlet = product.isOutlet;
                                data.brand    = product.brandName;
                                data.hasImage = product.hasImage;
                                data.fetchedProducts.push(product);

                            } else {

                                //product data error, for example brand could be missing
                                data.message  = "";
                                data.brand    = "";
                                data.error = true;
                            }

                            await this.upload(data);
                        }
                        catch (error) {
                            this.setFailed(data, "Filename does not have valid SKU");
                            this.displaySkuFinder = true;
                            return;
                        };
                } else {
                    this.setFailed(data, "Filename does not start with 10-character SKU");
                    this.displaySkuFinder = true;
                    return;
                }
            },
            fileInfoProductKeyup(data) {

                //parse data
                const styles = data.enteredProducts
                    .replace(/,/g, " ").split(" ")
                    .filter(p => p.trim().length)
                    .map(p => p.substring(0,6));

                //if user deletes a style remove it from fetchedProducts
                for (const prod of data.fetchedProducts) {
                    if (Util.indexOfIgnoreCase(styles, prod.style) === -1) {
                        data.fetchedProducts.splice(data.fetchedProducts.indexOf(prod), 1);
                    }
                }

                const fetched = this.fileInfoFetchedStyles(data);

                for (const style of styles) {

                    //fetch if product hasn't been fetched and is 6 characters
                    if (Util.indexOfIgnoreCase(fetched, style) === -1 && style.length === 6) {

                        this.$http.get(`/rs/product/style/${style}`)
                            .then(response => {
                                if (response.data) {

                                    //check that product was not fetched twice due to network latency
                                    const product = response.data;
                                    const fetched = this.fileInfoFetchedStyles(data);
                                    if (Util.indexOfIgnoreCase(fetched, product.style) === -1) {
                                        data.fetchedProducts.push(product);
                                    }
                                } else {
                                    this.removeProductFromFileInfoData(data, style);
                                }
                            }).catch(error => {
                                this.removeProductFromFileInfoData(data, style);
                            }
                        );
                    }
                }
            },
            removeProductFromFileInfoData(data, style) {
                this.addMessage(`Invalid REI style: ${style}`);
                data.enteredProducts = data.enteredProducts.replace(style, "");
            },
            fileInfoFetchedStyles(data) {
                return data.fetchedProducts.map(p => p.style);
            },
            fileInfoUpload(data) {

                //return if no styles have been entered
                if (data.fetchedProducts.length === 0) {
                    this.addMessage("Please enter at least one style for upload");
                    return;
                }

                data.status = UPLOAD_STATUS.UPLOADING;
                data.message = "";
                data.brand = data.fetchedProducts[0].brandName;
                this.upload(data);
            },
            async upload(data) {

                let uploadRequest = {
                    name:  this.name,
                    email: this.email,
                    styleId: data.styleId,
                    brand: this.config.productInfo !== PRODUCT_INFO.BRAND ? data.brand : this.brand,
                    notes: this.notes,
                    filename: normalizeFileName(data.file.name),
                    type:  this.config.uploadType.id,
                    hasImage: data.hasImage,
                    outlet: data.isOutlet,
                    products: data.fetchedProducts,
                    error: data.error
                };

                let formData = new FormData();
                formData.append("file" , data.file);
                formData.append("uploadRequest", JSON.stringify(uploadRequest));

                try {
                    const response = await this.$http.post("/rs/asset", formData);

                    let status = response.data;

                    if (status.status){
                        data.status = UPLOAD_STATUS.UPLOADED;
                        data.message = "Done. Thanks!";
                        this.uploaded = true;
                        this.successful++;

                        //display the video upload message after the first successful image upload
                        if (this.config.uploadType === UPLOAD_TYPE.IMAGE && !this.haveDisplayedVideoUploader) {
                            this.displayVideoUploader = true;
                            this.haveDisplayedVideoUploader = true;
                        }
                        return;
                    } else {
                        this.setFailed(data, "Upload Error");
                        return;
                    }
                }
                catch (error) {

                    let message;
                    let status = error.status || error.response.status;

                    if (status === 401) {
                        message = this.unauthorized;
                    } else if (status === 400) {
                        message = error.response.data.errors.join(", ");
                    } else {
                        //default error message for something like OutOfMemoryErrors
                        message = "Upload failed";
                    }

                    this.setFailed(data, message);
                }
            },
            getExtension(filename){
                return filename.substring(filename.lastIndexOf(".", filename) + 1).toLowerCase();
            },
            clearUploaded() {
                this.files = this.files.filter(file => file.status !== UPLOAD_STATUS.UPLOADED);
                this.uploaded = false;
            },
            clearFailed() {
                this.files = this.files.filter(file => file.status !== UPLOAD_STATUS.FAILED);
                this.failed = false;
            },
            setFailed(data, message) {
                data.status = UPLOAD_STATUS.FAILED;
                data.message = message;
                this.failed = true;
            },
            navigateToVideoUploader() {
                this.displayVideoUploader = false;
                this.clearUploaded();
                this.clearFailed();
                this.$router.push({name : 'upload', params: { type: 'video' }});
            },
            addMessage(message) {
                this.$store.commit("addMessage", message);
            },
            requestUploadList() {
                if (!this.validEmail) {
                    this.addMessage("Please enter a valid email address");
                    return;
                }
                this.loading = true;
                this.$http.get("/rs/uploadList", {
                    params: {
                        email: this.email
                    }
                }).then(response => {
                    this.addMessage("Email sent");
                    this.loading = false;
                }).catch(error => {
                    this.addMessage("An error occurred while sending the email");
                    this.loading = false;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../../scss/base/vars";
    @import "../../../scss/base/mixins";

    #target {
        height: 400px;
        overflow: auto;
        border: 3px dashed gray;
        border-radius: 5px;
        padding: 0;
        background-color: lightblue;

        .file-details {
            width: 100%;
            min-height: 40px;
            border-bottom: 2px solid gray;
            margin: 0;

            & > p {
                font-size: 150%;
                float: left;
                width: 51%;
                margin-left: 12%;
            }

            .detail {
                float: left;
                white-space: nowrap;
                overflow: hidden;

                p {
                    display: inline;
                    font-size: 175%;
                    padding-left: 10px;
                }
            }

            .status {
                width: 10%;
                padding-left: 5px;
            }

            .desc {
                width: 40%;
                text-align: center;
                float: left;
            }

            .filename {
                width: 50%;
                float: left;
                text-align: center;
            }

            .file-info-entry {
                width: 40%;
                margin-left: 10%;
            }

            .file-info-text {
                width: 67%;
                margin: 2px 0 10px 5%;
                float: left;
            }

            .file-info-button {
                width: 20%;
                margin: 2px 0 0 7%;
                float: left;
            }
        }

        .drop-text {
            text-align: center;
            margin-top: 170px;
        }

        .clearfix::after {
            content: " ";
            display: block;
            height: 0;
            clear: both;
        }
    }

    .drag, .over {
        border: 3px solid #a1c900!important;
        -webkit-transition: background-color .25s;
        transition: background-color .25s;
    }

    .download-btn, .failed-btn {
        @include reiButton;
        max-width: 200px;
        min-width: 130px;
        margin-top: 15px;
        float: right;
    }

    .failed-btn {
        background-image: -webkit-linear-gradient(top, #e32225, #d01900);
        background-image: -moz-linear-gradient(top, #e32225, #d01900);
        background-image: -ms-linear-gradient(top, #e32225, #d01900);
        background-image: -o-linear-gradient(top, #e32225, #d01900);
        background-image: linear-gradient(to bottom, #e32225, #d01900);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#e32225, endColorstr=#d01900);
    }

    .vendor-details {
        margin-bottom: 15px;

        .input-border {
            border: 3px solid $reiOrange;
        }
    }

    .logo-details {

        h3 {
            text-decoration-line: underline;
            font-style: italic;
        }

        ul {
            font-size: 120%;
        }
    }

    .icon-container {
        text-align: center;
        opacity: 0.2;
    }

    .request-upload {
        input {
            float: left;
        }
        p {
            clear: left;
        }
    }

    .reload-image {
        margin-top: 27px;
        margin-right: 10px;
        float: right;
        opacity: 1;
        transition: opacity 0.1s;
    }

    .reload-hide {
        opacity: 0;
    }

    //firefox needs this to display file input with form-control properly
    @-moz-document url-prefix() {
        .file-input {
            padding: 0;
        }
    }
</style>