<template>
  <cdr-modal
      aria-describedby="description"
      :opened="openModal"
      :label="title"
      :role="role"
      @closed="confirmAnswer('no')">
    <template #title>
      <cdr-text tag="h4" class="title-header">
                <span v-if="isAlert">
                    <icon-warning-fill/>
                </span>
        <span>{{ title }}</span>
      </cdr-text>
    </template>
    <cdr-text tag="p" id="description">
      <span v-if="showContent">{{ content }}</span>
      <slot v-else name="content"/>
    </cdr-text>
    <div class="ui-buttons">
      <cdr-button
          class="ui-button"
          modifier="sale"
          @click="confirmAnswer('yes')">
        {{ labelYesOption }}
      </cdr-button>
      <cdr-button
          class="ui-button"
          modifier="secondary"
          @click="confirmAnswer('no')">
        {{ labelNotOption }}
      </cdr-button>
    </div>
  </cdr-modal>
</template>

<script>
import {
  CdrText,
  CdrButton,
  CdrModal,
  IconWarningFill,
} from "@rei/cedar";
import {ref, computed, onMounted} from 'vue';
import util from "../../../util/util";

export default {
  name:       'campaign-confirm-modal',
  components: {
    CdrText,
    CdrButton,
    CdrModal,
    IconWarningFill,
  },
  props:      {
    title:          {
      type:     String,
      required: true,
    },
    content:        {
      type:     String,
      required: false,
    },
    role:           {
      type:     String,
      required: false,
      validator ( value ) {
        return ['alertdialog', 'dialog'].includes( value );
      },
    },
    labelYesOption: {
      type:     String,
      required: false,
      default:  'Yes',
    },
    labelNotOption: {
      type:     String,
      required: false,
      default:  'No',
    },
    showModal:      {
      type:     Boolean,
      required: false,
      default:  false,
    },
  },
  setup ( props, { emit } ) {
    const openModal = ref( false );
    const info = ref( {} );

    const confirmAnswer = ( option ) => {
      const data = info.value;
      const event = {
        answer: (option === 'yes'),
        metadata: data,
      };
      emit( 'confirm', event );
      openModal.value = false;
    };

    const showConfirmationModal = (metadata) => {
      openModal.value = true;
      info.value = {
        ...metadata,
      };
    };

    const isAlert = computed( () => {
      return props.role === 'alertdialog';
    } );

    const showContent = computed( () => {
      return !util.isEmpty( props.content );
    } );

    onMounted( () => {
      openModal.value = props.showModal;
    } );

    return {
      info,
      openModal,
      confirmAnswer,
      showConfirmationModal,
      isAlert,
      showContent,
    };
  },
}
</script>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/docsite/scss/cdr-tokens.scss";
@import "@rei/cdr-component-variables/dist/scss/index.scss";

.title-header {
  flex-direction: inherit;
}

.ui-buttons {
  margin-top: $cdr-space-one-x;
  margin-bottom: $cdr-space-one-x;
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
}

.ui-buttons > .ui-button {
  display: flex;
  align-items: center;
}
</style>