import Util from "./util";
import moment from "moment";

const defaultFormatDateInput = "YYYY-MM-DD";

export default {
  checkValidEmail(text) {
    const value = Util.isEmpty(text) ? "" : text;
    const realText = value.trim();
    if (!Util.validateEmail(realText)) {
      return "invalid-email";
    } else {
      return null;
    }
  },

  checkValidText(text, options) {
    const value = Util.isEmpty(text) ? "" : text;
    const minChars = options?.minChars || 0;
    const maxChars = options?.maxChars || 0;
    const realText = value.trim();
    const lenText = realText.length;
    if (
      minChars !== 0 &&
      maxChars !== 0 &&
      (lenText < minChars || lenText > maxChars)
    ) {
      return "invalid-chars-length-range";
    } else if (minChars !== 0 && lenText < minChars) {
      return "invalid-min-chars-length";
    } else if (maxChars !== 0 && lenText > maxChars) {
      return "invalid-max-chars-length";
    } else {
      return null;
    }
  },

  checkValidDate(value, required) {
    if (required && Util.isEmpty(value)) {
      return "required";
    } else if (Util.isEmpty(value)) {
      return null;
    }
    const date = moment(value, defaultFormatDateInput, true);
    if (!date.isValid()) {
      return "invalid-date";
    } else {
      return null;
    }
  },

  checkValidRangeDate(beginValue, endValue, required) {
    const msgBegin = this.checkValidDate(beginValue, false);
    const msgEnd = this.checkValidDate(endValue, required);
    if (!Util.isEmpty(msgBegin)) {
      return msgBegin;
    }
    if (!Util.isEmpty(msgEnd)) {
      return msgEnd;
    }
    const startDate = moment(beginValue, defaultFormatDateInput, true);
    const endDate = moment(endValue, defaultFormatDateInput, true);
    if (startDate.isAfter(endDate, "day")) {
      return "invalid-date-range";
    } else {
      return null;
    }
  },

  checkValidOption(optionValue, options) {
    const index = options.findIndex((option) => option.value === optionValue);
    if (index < 0) {
      return "invalid-option";
    } else {
      return null;
    }
  },

  checkValidContentAssets(assets) {
    if (!assets) {
      return null;
    }
    const keys = Object.keys(assets);
    const items = keys.filter((key) => assets[key].uploaded) || [];
    if (items.length !== keys.length) {
      return "invalid-assets";
    } else {
      return null;
    }
  },
};
