<template>
  <div class="row">
    <div class="col-sm-9 col-sm-offset-1">
      <div class="campaign-entry">
        <campaign-confirm-modal
          ref="deleteConfirmationModal"
          title="Confirm file deletion"
          content="Are you sure to confirm this operation?"
          role="alertdialog"
          label-yes-option="Confirm"
          label-not-option="Cancel"
          @confirm="deleteRowGridHandler"
        />
        <form class="ui-form" @submit.prevent="handleSubmitCampaign">
          <div class="ui-header">
            <span>Media Rights Form</span>
            <hr class="ui-line" />
          </div>
          <div class="ui-messages">
            <p>
              Please fill out all required fields* and review the Supplier
              Materials License Agreement before submitting all content.
            </p>
            <p>
              The usage rights selected in this form should apply to the images
              placed in the uploader.
            </p>
            <p>
              For any materials that have different usage rights/dates please
              complete a New Media Rights Form. A copy of the final License
              Agreement will be sent to the email provided below.
            </p>
          </div>
          <div class="ui-title">
            <span>
              Have questions about this form? Please reach out to your REI
              contact.
            </span>
          </div>
          <div class="ui-custom-component" v-if="hasCampaignErrors">
            <campaign-notification-banner
              :type="getCampaignErrorType"
              :summary="getCampaignErrorSummary"
              :content="getCampaignErrorContent"
            />
          </div>
          <div class="ui-input">
            <cdr-input
              v-model="mediaRight.name"
              id="media-right-name"
              name="name"
              type="text"
              background="primary"
              label="Name"
              required
              aria-describedby="error-by-name"
              :error="hasNameErrors"
              :disabled="isLoadingForm"
              @input="validateInputHandler"
              @blur="validateInputHandler"
            >
              <template #helper-text-bottom>
                Must be 4 or more characters
              </template>
              <template #error>
                <span id="error-by-name">
                  {{ getNameErrors }}
                </span>
              </template>
            </cdr-input>
          </div>
          <div class="ui-input">
            <cdr-input
              v-model="mediaRight.title"
              id="media-right-title"
              name="title"
              type="text"
              background="primary"
              label="Job Title"
              required
              aria-describedby="error-by-title"
              :error="hasTitleErrors"
              :disabled="isLoadingForm"
              @input="validateInputHandler"
              @blur="validateInputHandler"
            >
              <template #helper-text-bottom>
                Must be 2 or more characters
              </template>
              <template #error>
                <span id="error-by-title">
                  {{ getTitleErrors }}
                </span>
              </template>
            </cdr-input>
          </div>
          <div class="ui-input">
            <cdr-input
              v-model="mediaRight.email"
              id="media-right-email"
              name="email"
              type="email"
              background="primary"
              label="Email"
              required
              aria-describedby="error-by-email"
              :error="hasEmailErrors"
              :disabled="isLoadingForm"
              @input="validateInputHandler"
              @blur="validateInputHandler"
            >
              <template #helper-text-bottom> Must be a valid email </template>
              <template #error>
                <span id="error-by-email">
                  {{ getEmailErrors }}
                </span>
              </template>
            </cdr-input>
          </div>
          <div class="ui-input">
            <cdr-input
              v-model="mediaRight.brand"
              id="media-right-brand"
              name="brand"
              type="text"
              background="primary"
              label="Vendor brand"
              required
              aria-describedby="error-by-brand"
              :error="hasBrandErrors"
              :disabled="isLoadingForm"
              @input="validateInputHandler"
              @blur="validateInputHandler"
            >
              <template #helper-text-bottom>
                Must be 2 or more characters
              </template>
              <template #error>
                <span id="error-by-brand">
                  {{ getBrandErrors }}
                </span>
              </template>
            </cdr-input>
          </div>
          <div class="ui-group-component">
            <div class="ui-header">
              <span>1. Usage Rights Time Period*</span>
            </div>
            <div class="ui-input">
              <cdr-input
                v-model="mediaRight.usageRightsEmbargoDate"
                id="media-right-embargo-start-date"
                name="usageRightsEmbargoDate"
                background="primary"
                label="Embargo (start) date"
                type="date"
                required
                aria-describedby="error-by-embargo-start-date"
                :error="hasEmbargoStartDateErrors"
                :disabled="isLoadingForm"
                @input="validateInputHandler"
                @blur="validateInputHandler"
              >
                <template #helper-text-top>
                  If applicable, you may specify the first date on which these
                  materials can be made publicly available.
                </template>
                <template #error>
                  <span id="error-by-embargo-start-date">
                    {{ getEmbargoStartDateErrors }}
                  </span>
                </template>
              </cdr-input>
            </div>
          </div>
          <div class="ui-custom-component">
            <campaign-radio-group
              v-model="mediaRight.usageRightsTimePeriod"
              id="media-right-use-right-per"
              name="usageRightsTimePeriod"
              background="primary"
              required
              :options="getYesOrNotOptions"
              :disabled="isLoadingForm"
              @change="validateInputHandler"
            >
              <template #helper>
                <cdr-text tag="span" class="ui-message-bold">
                  Is the brand willing and able to grant REI usage rights in
                  perpetuity (indefinitely)?
                </cdr-text>
              </template>
            </campaign-radio-group>
          </div>
          <div class="ui-input" v-if="showEndDate">
            <cdr-input
              v-model="mediaRight.usageRightsEndDate"
              id="media-right-embargo-end-date"
              name="usageRightsEndDate"
              background="primary"
              label="End date"
              type="date"
              required
              aria-describedby="error-by-embargo-end-date"
              :error="hasEmbargoEndDateErrors"
              :disabled="isLoadingForm"
              @input="validateInputHandler"
              @blur="validateInputHandler"
            >
              <template #helper-text-bottom> Must be a valid date </template>
              <template #error>
                <span id="error-by-embargo-end-date">
                  {{ getEmbargoEndDateErrors }}
                </span>
              </template>
            </cdr-input>
          </div>
          <div class="ui-custom-component">
            <campaign-radio-group
              v-model="mediaRight.usageRightsExclusivity"
              id="media-right-use-rights-ex"
              name="usageRightsExclusivity"
              background="primary"
              title="2. Usage Rights Exclusivity"
              required
              :options="getYesOrNotOptions"
              :disabled="isLoadingForm"
              @changed="validateInputHandler"
            >
              <template #helper>
                <cdr-text tag="span" class="ui-message-bold">
                  Is the brand willing and able to grant exclusive rights to REI
                  for any period?
                </cdr-text>
              </template>
            </campaign-radio-group>
          </div>
          <div class="ui-custom-component">
            <campaign-radio-group
              v-model="mediaRight.usageMediaRights"
              id="media-right-media-rights"
              name="usageMediaRights"
              background="primary"
              title="3. Media Rights"
              required
              :error="getMediaRightError"
              :options="getMediaRightOptions"
              :disabled="isLoadingForm"
              @changed="validateInputHandler"
            >
              <template #info>
                <campaign-notification-banner type="info">
                  <template #summary>
                    Please refer to
                    <a
                      class="cdr-link-default-campaign-entry"
                      target="_blank"
                      :href="getCampaignLegalDocumentUrl"
                    >
                      Supplier Materials License Agreements
                    </a>
                    for the definitions of the options below.
                  </template>
                </campaign-notification-banner>
              </template>
              <template #helper>
                <cdr-text tag="span" class="ui-message">
                  As more fully set forth in the
                  <a
                    class="cdr-link-default-campaign-entry"
                    target="_blank"
                    :href="getCampaignLegalDocumentUrl"
                  >
                    Supplier Materials License Agreement </a
                  >, Brand is <br />
                  granting REI the right to use the materials being submitted
                  through the portal (the<br />
                  “Materials”) to advertise, market, promote and publicize the
                  Brand and REI and their<br />
                  respective products, services and general goodwill in the
                  media checked off below.
                </cdr-text>
              </template>
            </campaign-radio-group>
          </div>

          <div class="ui-custom-component">
            <campaign-upload-input
              ref="campaignUploadMediaInput"
              title="Assets"
              dropdown-message="Drop assets here"
              multiple
              hide-input
              :hide-buttons="false"
              :error="hasMediaFilesErrors"
              :disabled="isLoadingForm"
              @selected-files="uploadFiles"
            >
              <template #error>
                <span id="error-by-media-assets">
                  {{ getMediaFilesError }}
                </span>
              </template>
            </campaign-upload-input>
          </div>

          <div class="ui-custom-component" v-if="checkAssetsIsNotEmpty">
            <campaign-notification-banner :type="getMediaAssetMessageType">
              <template v-if="!hasAssetMetadataListGrid" #summary>
                Provide any optional image related data in the fields adjacent to the image thumbnail.
              </template>
              <template v-else #summary>
                One or more assets are invalid. Hover over the filename for error details. 
                Remove all invalid assets by clicking <a class="cdr-link-default-campaign-entry"
                   @click="deleteInvalidAssets"
                >here</a>
              </template>
            </campaign-notification-banner>
          </div>

          <div class="ui-common" v-if="checkAssetsIsNotEmpty">
            <campaign-grid-table
              data-testid="media-right-files-grid"
              :media-rows="mediaRight.assetMetadataList"
              @grid-row-deleted="deleteRowGrid"
              @grid-rows-changed="changeContentGridHandler"
            />
          </div>

          <div class="ui-group-component ui-custom-component">
            <cdr-checkbox
              v-model="mediaRight.licenseAgreement"
              id="media-right-license-agreement"
              name="licenseAgreement"
              background="primary"
              :disabled="isLoadingForm"
              @change="validateCampaignFormHandler"
              >By clicking the Submit button, I agree on behalf of the Brand
              identified above that REI may use the materials being submitted as
              set forth in the
              <a
                class="cdr-link-default-campaign-entry"
                target="_blank"
                :href="getCampaignLegalDocumentUrl"
              >
                Suppliers Materials License Agreement </a
              >.
            </cdr-checkbox>
          </div>
          <div class="ui-group-component">
            <div class="ui-buttons">
              <div class="ui-button">
                <cdr-button
                  type="submit"
                  id="media-right-send-button"
                  :data-submittable="!isDisabledButton"
                  :disabled="isDisabledButton"
                >
                  <template #icon-left>
                    <icon-brand-rei-ice-axes inherit-color />
                  </template>
                  Submit
                </cdr-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import moment from "moment";
import * as lodash from "lodash";
import {
  CdrText,
  CdrButton,
  CdrInput,
  CdrCheckbox,
  IconBrandReiIceAxes,
  CdrLink,
} from "@rei/cedar";
import CampaignRadioGroup from "./CampaignRadioGroup.vue";
import CampaignUploadInput from "./CampaignUploadInput.vue";
import CampaignGridTable from "./grid-table/CampaignGridTable.vue";
import CampaignConfirmModal from "./modal/CampaignConfirmModal.vue";
import CampaignNotificationBanner from "./notification/CampaignNotificationBanner.vue";
import Util from "../../util/util";
import campaignOptions from "../../data/CampaignOptions";
import Validators from "../../util/validators";
import { useCampaignFormApi } from "../../composables/campaign";

const MESSAGE_TYPES = {
  INFORMATION: "info",
  WARNING: "warning",
  ERROR: "error",
};

export default {
  name: "campaign-entry",
  components: {
    CdrText,
    CdrButton,
    CdrInput,
    CdrCheckbox,
    IconBrandReiIceAxes,
    CdrLink,
    CampaignRadioGroup,
    CampaignUploadInput,
    CampaignGridTable,
    CampaignConfirmModal,
    CampaignNotificationBanner,
  },
  setup() {
    const {
      uploadAssetApi,
      deleteAssetApi,
      createCampaignApi,
      getCampaignApi,
      getEnvironmentValue,
    } = useCampaignFormApi();
    const route = useRoute();
    const deleteConfirmationModal = ref(null);
    const campaignUploadMediaInput = ref(null);
    const loadingForm = ref(false);
    const mediaRight = reactive({
      name: null,
      title: null,
      email: null,
      brand: null,
      usageMediaRights: null,
      usageRightsExclusivity: "no",
      usageRightsTimePeriod: "yes",
      usageRightsEmbargoDate: moment(new Date()).format("YYYY-MM-DD"),
      usageRightsEndDate: null,
      licenseAgreement: false,
      assetMetadataList: {},
    });
    const errors = reactive({
      name: "",
      title: "",
      email: "",
      brand: "",
      usageMediaRights: "",
      usageRightsExclusivity: "",
      usageRightsTimePeriod: "",
      usageRightsEmbargoDate: "",
      usageRightsEndDate: "",
      assetMetadataList: "",
      assetMetadataListGrid: "",
      licenseAgreement: "",
    });
    const notificationStatusCode = ref(0);

    const ERROR_MESSAGES = {
      "required-text": () => "This field is required",
      "required-action": () => "It must be checked",
      "invalid-min-chars-length": (minChars) =>
        `Error: please enter ${minChars} or more characters`,
      "invalid-max-chars-length": (maxChars) =>
        `Error: please enter ${maxChars} or less characters`,
      "invalid-date": () => "Error: please enter a valid date",
      "invalid-email": () => "Error: please enter a valid email",
      "invalid-date-range": () =>
        "Error: enter a valid end date after the start date",
      "invalid-option": () => "Please make a selection",
      "required-files": () => "Please upload at least one asset",
      "invalid-assets": () =>
        "There are invalid uploaded assets in the grid, please confirm that and remove them",
    };
    const FIELD_VALIDATORS = {
      fields: {
        name: (value) => {
          if (Util.isEmpty(value)) {
            return "required-text";
          }
          return Validators.checkValidText(value, { minChars: 4 });
        },
        title: (value) => {
          if (Util.isEmpty(value)) {
            return "required-text";
          }
          return Validators.checkValidText(value, { minChars: 2 });
        },
        email: (value) => {
          if (Util.isEmpty(value)) {
            return "required-text";
          }
          return Validators.checkValidEmail(value);
        },
        brand: (value) => {
          if (Util.isEmpty(value)) {
            return "required-text";
          }
          return Validators.checkValidText(value, { minChars: 2 });
        },
        usageRightsEmbargoDate: (value) =>
          Validators.checkValidDate(value, true),
        usageRightsTimePeriod: (value) => {
          const resetEndDate = value === "yes";
          if (resetEndDate) {
            mediaRight.usageRightsEndDate = null;
            errors.usageRightsEndDate = null;
          }
          return Validators.checkValidOption(value, campaignOptions.GENERAL);
        },
        usageRightsEndDate: (value) => {
          const required = mediaRight.usageRightsTimePeriod === "no";
          const startValue = mediaRight.usageRightsEmbargoDate;
          const endValue = value;
          return Validators.checkValidRangeDate(startValue, endValue, required);
        },
        usageRightsExclusivity: (value) =>
          Validators.checkValidOption(value, campaignOptions.GENERAL),
        usageMediaRights: (value) =>
          Validators.checkValidOption(value, campaignOptions.MEDIA_RIGHT),
        assetMetadataList: (value) => {
          if (!value) {
            return null;
          }
          const keys = Object.keys(value);
          if (Util.isEmptyArray(keys)) {
            return "required-files";
          } else {
            return null;
          }
        },
        assetMetadataListGrid: (value) => {
          const assets = value || {};
          return Validators.checkValidContentAssets(assets);
        },
        licenseAgreement: (value) => {
          return !value ? "required-action" : null;
        },
      },
    };

    const validateInputHandler = (event) => {
      const info = event.target || event;
      const fieldName = info.name;
      const arg = fieldName === "name" ? 4 : 2;
      const errorCode = FIELD_VALIDATORS.fields[fieldName](info.value);
      if (!Util.isEmpty(errorCode)) {
        errors[fieldName] = ERROR_MESSAGES[errorCode](arg);
      } else {
        errors[fieldName] = null;
      }
    };

    const validateCampaignFormHandler = () => {
      const fields = Object.keys(errors);
      fields.forEach((field) => {
        if (field === "assetMetadataListGrid") {
          validateInputHandler({
            name: field,
            value: mediaRight.assetMetadataList,
          });
        } else {
          validateInputHandler({ name: field, value: mediaRight[field] });
        }
      });
    };

    const changeContentGridHandler = () => {
      validateInputHandler({
        name: "assetMetadataList",
        value: mediaRight.assetMetadataList,
      });
      validateInputHandler({
        name: "assetMetadataListGrid",
        value: mediaRight.assetMetadataList,
      });
    };

    const getErrorMessage = (name) => {
      return errors[name];
    };

    const hasFieldError = (name) => {
      const error = getErrorMessage(name);
      return !Util.isEmpty(error);
    };

    const uploadAssetService = async (rowValue, campaignEntryId) => {
      const id = rowValue.id;
      mediaRight.assetMetadataList[id].loading = true;
      try {
        const result = await uploadAssetApi({
          campaignEntryId,
          asset: rowValue.asset,
        });
        mediaRight.assetMetadataList[id].loading = false;
        mediaRight.assetMetadataList[id].uploaded = true;
        mediaRight.assetMetadataList[id].data = result.campaignEntryAssetId;
      } catch (error) {
        mediaRight.assetMetadataList[id].loading = false;
        mediaRight.assetMetadataList[id].uploaded = false;
        mediaRight.assetMetadataList[id].error = error;
      }
    };

    const deleteRowGridHandler = (event) => {
      if (!event.answer) {
        return Promise.resolve();
      }
      const campaignEntryId = route.params?.campaignEntryId;
      const { id, data } = event.metadata;
      mediaRight.assetMetadataList[id].loading = true;
      if (!data.uploaded) {
        mediaRight.assetMetadataList[id].loading = false;
        refreshMediaAssets(id);
        changeContentGridHandler();
        return Promise.resolve();
      }
      return deleteAssetApi({
        campaignEntryId,
        campaignEntryAssetId: data.data,
      })
        .then(() => {
          mediaRight.assetMetadataList[id].loading = false;
          refreshMediaAssets(id);
          changeContentGridHandler();
        })
        .catch(() => {
          mediaRight.assetMetadataList[id].loading = false;
          refreshMediaAssets(id);
          changeContentGridHandler();
        });
    };

    const uploadFiles = async (data) => {
      const campaignEntryId = route.params?.campaignEntryId;
      const files = data.files;
      const ids = Array.from({ length: files.length }, (_, i) =>
        Util.generateRowUUID()
      );
      for (let index = 0; index < files.length; index++) {
        const id = ids[index];
        mediaRight.assetMetadataList = {
          ...mediaRight.assetMetadataList,
          [id]: {
            data: null,
            columns: {
              asset: files[index],
              sku: "",
              casemark: "",
              color: "",
              size: "",
              photographer: "",
            },
            error: null,
            uploaded: false,
            loading: false,
          },
        };
      }
      const items = ids.map((id, index) =>
        uploadAssetService({ asset: files[index], id }, campaignEntryId)
      );
      await Promise.allSettled(items);
      changeContentGridHandler();
    };

    const getRowsLoading = () => {
      const ids = Object.keys(mediaRight.assetMetadataList);
      const outputs = ids.filter(
        (id) => mediaRight.assetMetadataList[id].loading
      );
      return !Util.isEmptyArray(outputs);
    };

    const setLoadingFlag = (flag) => {
      loadingForm.value = flag;
      const ids = Object.keys(mediaRight.assetMetadataList);
      ids.forEach((id) => {
        mediaRight.assetMetadataList[id].loading = flag;
      });
    };

    const setErrorMessages = (items) => {
      items.forEach(({ path, message }) => {
        switch (path) {
          case "campaignEntryId": {
            break;
          }
          case "assetMetadataList": {
            errors.assetMetadataList = message.replace(
              "assetMetadataList",
              "Asset list"
            );
            break;
          }
          default: {
            errors[path] = message;
            break;
          }
        }
      });
    };

    const getDataFromStorage = (field) => {
      return sessionStorage.getItem(field);
    };

    const loadDataFromStorage = () => {
      mediaRight.name = getDataFromStorage("name");
      mediaRight.title = getDataFromStorage("title");
      mediaRight.email = getDataFromStorage("email");
      mediaRight.brand = getDataFromStorage("brand");
    };

    const handleSubmitCampaign = async () => {
      const campaignEntryId = route.params?.campaignEntryId;
      setLoadingFlag(true);
      notificationStatusCode.value = 0;
      try {
        await createCampaignApi(campaignEntryId, mediaRight);
        setLoadingFlag(false);
      } catch (error) {
        setLoadingFlag(false);
        notificationStatusCode.value = error.status;
        if (error.status === 400) {
          setErrorMessages(error.errors);
        }
      }
    };

    const loadSubmissionCampaign = async () => {
      const campaignEntryId = route.params?.campaignEntryId;
      try {
        setLoadingFlag(true);
        const campaign = await getCampaignApi(campaignEntryId);
        const assetMetadataList = campaign.assetMetadataList;
        setLoadingFlag(false);
        if (Util.isEmptyArray(assetMetadataList)) {
          return Promise.resolve();
        }
        assetMetadataList.forEach((assetFile) => {
          const id = Util.generateRowUUID();
          mediaRight.assetMetadataList = {
            ...mediaRight.assetMetadataList,
            [id]: {
              data: assetFile.campaignEntryAssetId,
              columns: {
                asset: assetFile.asset,
                sku: "",
                casemark: "",
                color: "",
                size: "",
                photographer: "",
              },
              error: null,
              uploaded: true,
              loading: false,
            },
          };
        });
      } catch (error) {
        setLoadingFlag(false);
      }
    };

    const refreshMediaAssets = (assetId) => {
      const data = lodash.omit(mediaRight.assetMetadataList, [assetId]);
      mediaRight.assetMetadataList = {
        ...data,
      };
    };

    const deleteRowGrid = (event) => {
      deleteConfirmationModal.value.showConfirmationModal({
        ...event,
      });
    };

    const deleteInvalidAssets = async () => {
      const answer = true;
      const ids = Object.keys(mediaRight.assetMetadataList);
      const assets = ids
        .filter((id) => !mediaRight.assetMetadataList[id].uploaded)
        .map((id) => {
          const info = {
            data: mediaRight.assetMetadataList[id].data,
            uploaded: mediaRight.assetMetadataList[id].uploaded,
          };
          return deleteRowGridHandler({ answer, metadata: { id, data: info } });
        });
      await Promise.allSettled(assets);
    };

    const checkCampaignIsValid = () => {
      const errorName = hasFieldError("name");
      const errorTitle = hasFieldError("title");
      const errorBrand = hasFieldError("brand");
      const errorEmail = hasFieldError("email");
      const errorMediaRights = hasFieldError("usageMediaRights");
      const errorUseRightsEx = hasFieldError("usageRightsExclusivity");
      const errorUseRightsPer = hasFieldError("usageRightsTimePeriod");
      const errorEmbargoStartDate = hasFieldError("usageRightsEmbargoDate");
      const errorEmbargoEndDate = hasFieldError("usageRightsEndDate");
      const errorMediaFiles = hasFieldError("assetMetadataList");
      const errorMediaFilesGrid = hasFieldError("assetMetadataListGrid");
      const licenseAgreement = mediaRight.licenseAgreement;
      return (
        !errorName &&
        !errorTitle &&
        !errorBrand &&
        !errorEmail &&
        !errorMediaRights &&
        !errorUseRightsEx &&
        !errorUseRightsPer &&
        !errorEmbargoStartDate &&
        !errorEmbargoEndDate &&
        !errorMediaFiles &&
        !errorMediaFilesGrid &&
        licenseAgreement
      );
    };

    const getCampaignLegalDocumentUrl = computed(() => getEnvironmentValue("campaignLegalDocumentUrl"));

    const getMediaRightOptions = computed(() => campaignOptions.MEDIA_RIGHT);

    const getYesOrNotOptions = computed(() => campaignOptions.GENERAL);

    const showEndDate = computed(
      () => mediaRight.usageRightsTimePeriod === "no"
    );

    const isDisabledButton = computed(() => {
      return isLoadingForm.value || getRowsLoading() || !checkCampaignIsValid();
    });

    const checkAssetsIsNotEmpty = computed(() => {
      return !Util.isEmptyArray(Object.keys(mediaRight.assetMetadataList));
    });

    const hasNameErrors = computed(() => hasFieldError("name"));

    const hasTitleErrors = computed(() => hasFieldError("title"));

    const hasBrandErrors = computed(() => hasFieldError("brand"));

    const hasEmailErrors = computed(() => hasFieldError("email"));

    const hasEmbargoStartDateErrors = computed(() =>
      hasFieldError("usageRightsEmbargoDate")
    );

    const hasEmbargoEndDateErrors = computed(() =>
      hasFieldError("usageRightsEndDate")
    );

    const hasMediaFilesErrors = computed(() =>
      hasFieldError("assetMetadataList")
    );

    const hasAssetMetadataListGrid = computed(() =>
      hasFieldError("assetMetadataListGrid")
    );

    const getNameErrors = computed(() => getErrorMessage("name"));

    const getTitleErrors = computed(() => getErrorMessage("title"));

    const getBrandErrors = computed(() => getErrorMessage("brand"));

    const getEmailErrors = computed(() => getErrorMessage("email"));

    const getEmbargoStartDateErrors = computed(() =>
      getErrorMessage("usageRightsEmbargoDate")
    );

    const getEmbargoEndDateErrors = computed(() =>
      getErrorMessage("usageRightsEndDate")
    );

    const getMediaRightError = computed(() =>
      getErrorMessage("usageMediaRights")
    );

    const getMediaFilesError = computed(() =>
      getErrorMessage("assetMetadataList")
    );

    const getMediaAssetMessageType = computed(() => {
      const content = getErrorMessage("assetMetadataListGrid");
      return Util.isEmpty(content) ? MESSAGE_TYPES.INFORMATION : MESSAGE_TYPES.ERROR;
    });

    const isLoadingForm = computed(() => loadingForm.value);

    const hasCampaignErrors = computed(() => {
      const status = notificationStatusCode.value;
      const classStatus = Math.floor((status / 100) % 10);
      const statusListError = [1, 3, 4, 5];
      return statusListError.includes(classStatus);
    });

    const getCampaignErrorType = computed(() => {
      const status = notificationStatusCode.value;
      const classStatus = Math.floor((status / 100) % 10);
      if (classStatus === 4) {
        return MESSAGE_TYPES.WARNING;
      } else {
        return MESSAGE_TYPES.ERROR;
      }
    });

    const getCampaignErrorSummary = computed(() => {
      const type = getCampaignErrorType.value;
      if (type === MESSAGE_TYPES.WARNING) {
        return "There are incomplete or missing data";
      } else {
        return "There are internal problems";
      }
    });

    const getCampaignErrorContent = computed(() => {
      const type = getCampaignErrorType.value;
      if (type === MESSAGE_TYPES.WARNING) {
        return "Please check your request for any missing or incorrect parameters, and ensure that the data is provided in the correct format. If you need further assistance, please contact the server administrator.";
      } else {
        return "Sorry, something went wrong. Please try again later. If the issue persists, please contact support. Thank you.";
      }
    });

    onMounted(() => {
      // It would happen when the user clicked on the
      // "upload more" button...
      loadDataFromStorage();
      loadSubmissionCampaign();
    });

    return {
      mediaRight,
      errors,
      loadingForm,
      campaignUploadMediaInput,
      deleteConfirmationModal,
      getCampaignLegalDocumentUrl,
      getMediaRightOptions,
      getYesOrNotOptions,
      getMediaRightError,
      showEndDate,
      isDisabledButton,
      checkAssetsIsNotEmpty,
      hasNameErrors,
      hasTitleErrors,
      hasBrandErrors,
      hasEmailErrors,
      hasEmbargoStartDateErrors,
      hasEmbargoEndDateErrors,
      hasMediaFilesErrors,
      hasCampaignErrors,
      hasAssetMetadataListGrid,
      getNameErrors,
      getTitleErrors,
      getBrandErrors,
      getEmailErrors,
      getEmbargoStartDateErrors,
      getEmbargoEndDateErrors,
      getMediaFilesError,
      getMediaAssetMessageType,
      getCampaignErrorType,
      getCampaignErrorSummary,
      getCampaignErrorContent,
      isLoadingForm,
      deleteRowGrid,
      deleteRowGridHandler,
      deleteInvalidAssets,
      validateInputHandler,
      validateCampaignFormHandler,
      changeContentGridHandler,
      handleSubmitCampaign,
      uploadFiles,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/docsite/scss/cdr-tokens.scss";
@import "@rei/cdr-component-variables/dist/scss/index.scss";

.campaign-entry {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  font-family: $cdr-text-default-family;
}

.campaign-entry > .ui-form {
  width: 95%;
}

.campaign-entry > .ui-form > .ui-common {
  width: 99%;
}

.campaign-entry > .ui-form > .ui-header,
.campaign-entry > .ui-form > .ui-line,
.campaign-entry > .ui-form > .ui-title,
.campaign-entry > .ui-form > .ui-messages,
.campaign-entry > .ui-form > .ui-custom-component {
  width: 91%;
}

.campaign-entry > .ui-form > .ui-header {
  line-height: 8rem !important;
}

.campaign-entry > .ui-form > .ui-title,
.campaign-entry > .ui-form > .ui-header,
.campaign-entry > .ui-form > .ui-header > span {
  padding-bottom: $cdr-space-one-x;
}

.campaign-entry > .ui-form > .ui-messages > p {
  margin: 0;
}

.campaign-entry > .ui-form > .ui-messages > p:last-child {
  margin-bottom: $cdr-space-one-x;
}

.campaign-entry > .ui-form > .ui-line {
  border-color: #616161;
  border-width: 1px;
  text-align: left;
  justify-content: left;
}

.campaign-entry > .ui-form > .ui-header {
  @include cdr-text-heading-serif-1000;
}

.campaign-entry > .ui-form > .ui-messages,
.ui-message {
  @include cdr-text-body-300;
}

.ui-label-bold {
  @include cdr-text-heading-sans-200;
}

.campaign-entry > .ui-form > .ui-title,
.ui-message-bold {
  @include cdr-text-heading-sans-300;
}

.ui-input {
  width: 39%;
}

.ui-group-component {
  @include cdr-text-body-300;
}

.ui-group-component > .ui-header {
  @include cdr-text-subheading-sans-600;
}

.ui-group-component > .ui-buttons,
.ui-group-component {
  margin-top: $cdr-space-one-x;
}

.ui-group-component > .ui-header,
.ui-group-component > .ui-buttons {
  margin-bottom: $cdr-space-one-x;
}

.ui-group-component > .ui-buttons > .ui-button {
  display: flex;
  align-items: center;
}

.ui-group-component > .ui-buttons {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
}

.ui-group-component > div.ui-container {
  @include cdr-text-heading-sans-300;
  display: flex;
  height: 73px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #f4f2ed;
}

.cdr-link-default-campaign-entry {
  @include cdr-link-standalone-mixin;

  & {
    cursor: pointer;
  }
}
</style>
