<template>
    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row">
                <div class="col-xs-12">
                    <div class="ui-header">
                        <h3 class="title">Archived Assets</h3>
                        <img title="refresh" :class="{ 'reload-image': true, 'spin-image': loadingData }"
                            @click="fetchData" :src="reloadImg" width="22" height="17">
                    </div>
                    <p class="text-info">Assets are moved to the archive after download, and deleted after a month.</p>
                </div>
            </div>

            <div class="row term-container">
                <div class="col-xs-10 col-sm-6">
                    <input type="text" v-model="filterTerm" class="form-control"
                        placeholder="Filter by vendor name, email, brand, filename or style number" />
                </div>
            </div>

            <div class="row btn-container">
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.PI)" value="PI"
                        @click="filterByType(filterType.PI)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.IMAGE)" value="Images"
                        @click="filterByType(filterType.IMAGE)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.VIDEO)" value="Videos"
                        @click="filterByType(filterType.VIDEO)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.LOGO)" value="Logos"
                        @click="filterByType(filterType.LOGO)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.PDF)" value="PDF"
                        @click="filterByType(filterType.PDF)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.CAMPAIGN)" value="Campaign"
                        @click="filterByType(filterType.CAMPAIGN)" />
                </div>
            </div>

            <div class="row">

                <div v-if="loadingData" class="col-xs-12">
                    <h3>Loading...</h3>
                </div>

                <div v-else-if="!filtered.length" class="col-xs-12">
                    <h3>No Results</h3>
                </div>

                <div v-else class="col-xs-12 table-container">
                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>Archive Date</th>
                                <th>Brand</th>
                                <th>Filename - Click to Download</th>
                                <th>Name</th>
                                <th v-if="isVideoSelected">YouTube</th>
                                <th>Email</th>
                                <th v-if="isStyleDisplayed">
                                    Style</th>
                                <th>Processed By</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="asset in filtered" :key="asset.id"
                                :class="{ 'failed-upload': asset.uploadDto.status === 'Failed' }">
                                <td>{{ asset.archiveDate }}</td>
                                <td>{{ asset.uploadDto.brand }}</td>
                                <td class="download-file" @click="download(asset)">{{ asset.uploadDto.filename }}</td>
                                <td>{{ asset.uploadDto.name }}</td>
                                <th v-if="isVideoSelected"><a v-if="asset.youTubeUrl" :href="asset.youTubeUrl"
                                        target="_blank">View</a></th>
                                <td><a :href="'mailto:' + asset.uploadDto.email">{{ asset.uploadDto.email }}</a></td>
                                <td v-if="isStyleDisplayed">
                                    {{ asset.uploadDto.styleId }}</td>
                                <td>{{ asset.downloadedBy }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from 'vuex';
import reloadImg from "../../../resources/static/img/reload.png";
import saveFile from "../../util/saveFile";
import Util from "../../util/util";
import filterType from "../../data/UploadTypes";
import { useAdminApi } from "../../composables/admin";
import Config from "../../data/AdminConfig";

export default {
    name: "archived-assets",
    setup() {
        const { downloadArchiveAsset, getArchivedAssets } = useAdminApi();
        const $store = useStore();
        const assets = ref([]);
        const selectedType = ref(filterType.PI);
        const filterTerm = ref("");
        const loadingData = ref(true);

        const filtered = computed(() => {
            const filterAssets = assets.value;
            const filterData = filterTerm.value;
            const selType = selectedType.value;
            const isCampaign = selType.name === "CAMPAIGN";

            return filterAssets
                .filter(asset => Util.isEmpty(filterData) || asset.filterTerm.includes(filterData.toLowerCase()))
                .filter(asset => (isCampaign) ? Config.CAMPAIGN_TYPES.includes(asset.uploadDto.type) : asset.uploadDto.type.includes(selType.name));
        });

        const isVideoSelected = computed(() => selectedType.value.name === filterType.VIDEO.name);

        const isStyleDisplayed = computed(() => selectedType.value.name === filterType.VIDEO.name || selectedType.value.name === filterType.PDF.name);

        const btnClass = (type) => ({
            'form-control': true,
            'admin-btn': true,
            'btn-selected': selectedType.value.name === type.name
        });

        const filterByType = (type) => {
            selectedType.value = type;
        };

        const addMessage = (message) => {
            $store.commit("addMessage", message);
        };

        const download = async (asset) => {
            try {
                const response = await downloadArchiveAsset(asset);
                saveFile(response.data, response.filename);
            } catch (error) {
                addMessage(error.message);
            }
        };

        const fetchData = async () => {
            loadingData.value = true;
            try {
                const items = await getArchivedAssets();
                assets.value = items;
                loadingData.value = false;
            } catch (error) {
                addMessage("Error fetching asset uploaded data");
                loadingData.value = false;
            }
        };

        onMounted(() => {
            fetchData();
        });

        return {
            assets,
            filterType,
            selectedType,
            filterTerm,
            reloadImg,
            loadingData,
            filtered,
            isVideoSelected,
            isStyleDisplayed,
            btnClass,
            filterByType,
            download,
            fetchData,
            addMessage,
        };
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/base/vars";
@import "../../../scss/base/mixins";

.term-container {
    margin-top: 20px;
}

.btn-container {
    margin: 20px 0;

    .admin-btn {
        margin: 0 auto;

        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    .btn-selected {
        color: black;
        background-color: #ffa450;
    }
}

.table-container {
    height: auto;
    max-height: 800px;
    overflow: auto;

    table {
        thead {
            background-color: $mainOffsetColor;
            font-weight: bold;

            th {
                height: 50px;
            }
        }

        tbody {
            background-color: white;

            .download-file {

                color: $adminBlue;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>