<script>
import { computed } from "vue";
import {
  CdrText,
  CdrButton,
  CdrBanner,
  IconInformationFill,
  IconErrorFill,
  IconCheckFill,
  IconWarningFill,
  IconQuestionFill,
} from "@rei/cedar";
import svgSprite from "@rei/cedar-icons/dist/all-icons.svg";
import Util from "../../../util/util";
import icons from "../../../data/IconTypes";

export default {
  name: "campaign-notification-banner",
  components: {
    CdrText,
    CdrButton,
    CdrBanner,
    IconInformationFill,
    IconErrorFill,
    IconCheckFill,
    IconWarningFill,
    IconQuestionFill,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "default",
      validator(value) {
        return ["info", "success", "warning", "error", "default"].includes(
          value
        );
      },
    },
    summary: {
      type: String,
      required: false,
      default: "",
    },
    content: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const getBannerType = computed(() => props.type);

    const getIconElementType = computed(() => icons[props.type]);

    const isSummaryEmpty = computed(() => Util.isEmpty(props.summary));

    const isNotContentEmpty = computed(() => !Util.isEmpty(props.content));

    return {
      svgSprite,
      getBannerType,
      getIconElementType,
      isSummaryEmpty,
      isNotContentEmpty,
    };
  },
};
</script>

<template>
  <cdr-banner :type="getBannerType">
    <template #icon-left>
      <component :is="getIconElementType" inherit-color />
    </template>
    <cdr-text tag="span" class="ui-label-bold">
      {{ summary }}
      <slot name="summary" v-if="isSummaryEmpty" />
    </cdr-text>
    <template #message-body v-if="isNotContentEmpty">
      {{ content }}
    </template>
  </cdr-banner>
</template>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/docsite/scss/cdr-tokens.scss";
@import "@rei/cdr-component-variables/dist/scss/index.scss";

.ui-label-bold {
  @include cdr-text-heading-sans-200;
}
</style>
