<script>
import { ref, computed } from "vue";
import {
  CdrToast,
  CdrText,
  IconWarningFill,
  IconInformationFill,
  IconQuestionFill,
  IconCheckFill,
  IconErrorFill,
} from "@rei/cedar";
import Util from "../../../util/util";
import icons from "../../../data/IconTypes";

export default {
  name: "campaign-notification-toast",
  components: {
    CdrToast,
    CdrText,
    IconWarningFill,
    IconInformationFill,
    IconQuestionFill,
    IconCheckFill,
    IconErrorFill,
  },
  props: {
    defaultWarningMessage: {
      type: String,
      required: false,
    },
    defaultErrorMessage: {
      type: String,
      required: false,
    },
    defaultInfoMessage: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const contentValue = ref({});
    const openToast = ref(false);

    const displayToast = (content) => {
      contentValue.value = content;
      openToast.value = true;
    };

    const hideToast = () => {
      openToast.value = false;
    };

    const getMessages = (errors) => {
      if (Util.isEmptyArray(errors)) {
        return [];
      }
      return errors.map((error) => error.message);
    };

    const getToastType = () => {
      if (!contentValue.value) {
        return "default";
      }
      const status = contentValue.value.status;
      const isInfoRes = status / 100 === 1;
      const isSuccessRes = status / 200 === 1;
      const isRedirectRes = status / 300 === 1;
      const isClientErrorRes = status / 400 === 1;
      const isServerErrorRes = status / 500 === 1;
      if (isInfoRes) {
        return "info";
      } else if (isSuccessRes) {
        return "success";
      } else if (isRedirectRes || isServerErrorRes) {
        return "error";
      } else if (isClientErrorRes) {
        return "warning";
      } else {
        return "default";
      }
    };

    const isToastOpened = computed(() => {
      return openToast.value;
    });

    const getIconElementType = computed(() => icons[getToastType()]);

    const getToastContent = computed(() => {
      const type = getToastType();
      if (type === "warning" && !Util.isEmpty(props.defaultWarningMessage)) {
        return props.defaultWarningMessage;
      }
      if (type === "error" && !Util.isEmpty(props.defaultErrorMessage)) {
        return props.defaultErrorMessage;
      }
      if (type === "info" && !Util.isEmpty(props.defaultInfoMessage)) {
        return props.defaultInfoMessage;
      }
      const data = contentValue.value.data;
      return data.error ? getMessages(data.error) : [data.message];
    });

    return {
      contentValue,
      openToast,
      isToastOpened,
      getIconElementType,
      getToastContent,
      getToastType,
      displayToast,
      hideToast,
    };
  },
};
</script>

<template>
  <cdr-toast
    :auto-dismiss="false"
    :type="getToastType()"
    :open="isToastOpened"
    @closed="hideToast()"
  >
    <template #icon-left>
      <component :is="getIconElementType" inherit-color />
    </template>
    <cdr-text tag="p">
      <span v-for="message in getToastContent">
        {{ message }}
      </span>
      <br />
    </cdr-text>
  </cdr-toast>
</template>
