<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from 'vuex';
import { CdrButton } from '@rei/cedar';
import { useAdminApi } from "../../composables/admin";
import reloadImg from "../../../resources/static/img/reload.png";
import Util from "../../util/util";
import saveFile from "../../util/saveFile";
import moment from "moment";

const $store = useStore();
const records = ref([]);
const pageNumber = ref(0);
const pageSize = 20;
const loadingRecords = ref(false);
const nextPage = ref(false);
const { getCampaignMetadataList, createCampaignCSV } = useAdminApi();

const isLoadingRecords = computed(() => records.value.length === 0 && loadingRecords.value);

const getAwesomeIcon = computed(() => loadingRecords.value ? "sync" : "arrow-circle-down");

const addMessage = (message) => {
    $store.commit("addMessage", message);
};

const getDateFilter = () => {
    return moment(new Date()).subtract(20, "days").format("YYYY-MM-DD");
};

const fetchData = async () => {
    loadingRecords.value = true;
    try {
        const pageIndex = pageNumber.value;
        const startDate = getDateFilter();
        const page = await getCampaignMetadataList(startDate, pageIndex, pageSize);
        if (Util.isEmptyArray(page.content)) {
            addMessage("No new campaign metadata");
        } else {
            records.value = [
                ...records.value,
                ...page.content,
            ];
            pageNumber.value = Number(page.nextPageToken);
            nextPage.value = page.hasNextPage;
        }
        loadingRecords.value = false;
        return true;
    } catch (error) {
        addMessage("Error fetching campaign metadata");
        loadingRecords.value = false;
        return false;
    }
};

const clearRecords = async () => {
    records.value = [];
    pageNumber.value = 0;
    nextPage.value = false;
    await fetchData();
};

const downloadCSV = async (record) => {
    try {
        const response = await createCampaignCSV(record.id);
        saveFile(response.data, response.filename, response.type);
    } catch (error) {
        addMessage(error.message);
    }
};

onMounted(() => {
    fetchData();
});
</script>

<template>
    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">
            <div class="row download-controls">
                <div class="col-xs-6 col-sm-6">
                    <div class="ui-header">
                        <h3 class="title">Campaigns Metadata</h3>
                        <img width="22" height="17" title="refresh"
                            :class="{ 'reload-image': true, 'spin-image': loadingRecords }" :src="reloadImg"
                            @click="clearRecords" />
                    </div>
                    <p class="text-info">Download the campaign metadata from the last 20 days in a CSV file.</p>
                </div>
            </div>
            <div class="row">
                <div v-if="isLoadingRecords" class="col-xs-12">
                    <h3>Loading...</h3>
                </div>

                <div v-else-if="!records.length" class="col-xs-12">
                    <h3>No Results</h3>
                </div>

                <div v-else class="col-xs-12 table-container">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Submit Date</th>
                                <th>Job Number</th>
                                <th>Brand</th>
                                <th>Email</th>
                                <th>Number of Assets</th>
                                <th>Metadata File</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="record in records" :key="record.id">
                                <td class="metadata-date">{{ record.submitDate }}</td>
                                <td>{{ record.jobNumber }}</td>
                                <td>{{ record.brand }}</td>
                                <td><a :href="'mailto:' + record.email">{{ record.email }}</a></td>
                                <td>{{ record.numberOfAssets }}</td>
                                <td class="download-file" @click="downloadCSV(record)">CSV Download</td>
                            </tr>
                            <tr v-if="nextPage">
                                <td colspan="6">
                                    <cdr-button type="button" modifier="secondary" class="button"
                                        :disabled="loadingRecords" @click="fetchData()">
                                        <template #icon-left>
                                            <font-awesome-icon :icon="getAwesomeIcon" />
                                        </template>
                                    </cdr-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../../../scss/base/mixins";
@import "../../../scss/base/vars";

.button {
    width: 100%;
    align-items: center;
    text-align: center;
    display: inline-table;
}

.admin-panel {
    min-height: 600px;

    .download-controls {
        margin-bottom: 10px;

        .admin-btn {
            float: right;
        }
    }

    .table-container {
        height: auto;
        max-height: 500px;
        overflow: auto;

        table {
            thead {
                background-color: $mainOffsetColor;
                font-weight: bold;

                th {
                    height: 50px;
                }
            }

            tbody {
                background-color: white;

                tr {
                    border-left: 1px solid $mainBgColor;
                    border-right: 1px solid $mainBgColor;

                    td {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }

                .metadata-date {
                    min-width: 210px;
                }

                .download-file {
                    color: $adminBlue;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
</style>