import { getCurrentInstance } from "vue";
import formatDate from "../../util/formatDate";
import Util from "../../util/util";
import Config from "../../data/AdminConfig";

export const useAdminApi = () => {
  const instance = getCurrentInstance();
  const $http = instance?.appContext?.config.globalProperties.$http;

  const getPreparedParams = (map, separator) => {
    const attrs = Object.keys(map);
    const params = attrs.map((attr) => `${attr}=${map[attr]}`);
    return params.join(separator);
  };

  const getArchivedAssets = async () => {
    const response = await $http.get("rs/asset/data/archived");
    const assets = response.data;
    return assets.map((asset) => ({
      ...asset,
      archiveDate: formatDate(asset.archiveDate),
      filterTerm: `${asset.uploadDto.name}${asset.uploadDto.brand}${asset.uploadDto.filename}${asset.uploadDto.email}${asset.uploadDto.styleId}`.toLowerCase(),
    }));
  };

  const getUploadedAssets = async () => {
    const response = await $http.get("rs/asset/data/upload");
    const assets = response.data;
    return assets.map((upload) => ({
      ...upload,
      uploadDate: formatDate(upload.uploadDate),
      filterTerm: `${upload.name}${upload.brand}${upload.filename}${upload.email}${upload.styleId}`.toLowerCase(),
    }));
  };

  const getAssetType = (asset) => {
    const isCampaign = Config.CAMPAIGN_TYPES.includes(asset.uploadDto.type);
    if (isCampaign) {
      return asset.uploadDto.type.replace("_", " ");
    }
    return asset.filepath
      .substring(0, asset.filepath.lastIndexOf("/"))
      .replace("asset/", "")
      .toUpperCase();
  };

  const getAssets = async () => {
    const response = await $http.get("rs/asset/data");
    const assets = response.data;
    if (Util.isEmptyArray(assets)) {
      return {};
    }
    return assets.reduce((obj, asset) => {
      const type = getAssetType(asset);
      //videos are auto-uploaded to youtube, users don't need to download them
      //PDFs are displayed on a separate admin panel for upload to media tool, users don't need to download them
      // this condition was commented (type !== "VIDEO")
      if (type !== "PDF") {
        if (obj[type]) {
          obj[type].total = (obj[type].total || 0) + 1;
          obj[type].assets.push(asset);
          obj[type].assetTotal++;
        } else {
          obj[type] = {
            name: type,
            assets: [asset],
            selected: false,
            displayAssets: false,
            downloading: false,
            assetTotal: 1,
          };
        }
      }
      return obj;
    }, {});
  };

  const getAssetDownloadedBy = async (asset) => {
    const response = await $http.get(`rs/asset/downloadedBy/${asset.id}`);
    const user = Util.isEmpty(response.data) ? null : response.data;
    const result = { status: Config.DOWNLOAD_STATUS.FAILED };
    if (!Util.isEmpty(user) && user !== asset.username) {
      result.status = Config.DOWNLOAD_STATUS.DOWNLOADED;
    }
    return {
      ...result,
      data: response.data,
    };
  };

  const getCampaignMetadataList = async (startDate, pageNumber, pageSize) => {
    const params = {
      startDate,
      pageToken: pageNumber,
      pageSizeHint: pageSize,
    };
    const queryParams = getPreparedParams(params, "&");
    const { data } = await $http.get(
      `rs/campaign/submissions/metadata?${queryParams}`
    );
    if (Util.isEmptyArray(data.content)) {
      return data;
    }
    const entries = data.content;
    const records = entries.map((entry) => ({
      id: entry.campaignEntryId,
      jobNumber: entry.workfrontReference,
      email: entry.submitterEmail,
      brand: entry.brandName,
      numberOfAssets: entry.assetCount,
      submitDate: formatDate(entry.submitDate),
    }));
    return {
      content: records,
      nextPageToken: data.nextPageToken,
      hasNextPage: data.hasNextPage,
    };
  };

  const deleteAsset = async (asset) => {
    await $http.delete(`rs/asset/${asset.id}`);
    return true;
  };

  const downloadArchiveAsset = async (asset) => {
    const fp = asset.filepath;
    const option = {
      url: `rs/asset/archive/${asset.id}`,
      method: "GET",
      responseType: "blob",
    };
    try {
      const result = await $http(option);
      const filename = fp.substring(fp.lastIndexOf("/") + 1);
      return {
        data: result.data,
        filename,
      };
    } catch (error) {
      throw {
        message: `Error downloading archived asset ${fp}`,
        error,
      };
    }
  };

  const downloadAsset = async (asset) => {
    const fp = asset.filepath;
    const option = {
      url: `rs/asset/${asset.id}`,
      method: "GET",
      responseType: "blob",
    };
    try {
      const result = await $http(option);
      const filename = fp.substring(fp.lastIndexOf("/") + 1);
      return {
        data: result.data,
        filename,
      };
    } catch (error) {
      throw {
        message: `Error downloading asset ${fp}`,
        error,
      };
    }
  };

  const createCampaignCSV = async (campaignEntryId) => {
    try {
      const reFileName = /^attachment; filename="(.*)"$/gm;
      const response = await $http.get(
        `rs/campaign/submissions/${campaignEntryId}/csv`
      );
      const type = response.headers["content-type"] + ";";
      const result = reFileName.exec(response.headers["content-disposition"]);
      const filename = result && result[1].trim();
      const data = response.data;
      return {
        type,
        filename,
        data,
      };
    } catch (error) {
      throw {
        message: `Error creating the CSV campaign file ${campaignEntryId}`,
        error,
      };
    }
  };

  return {
    getArchivedAssets,
    getUploadedAssets,
    getAssets,
    getAssetDownloadedBy,
    getCampaignMetadataList,
    downloadArchiveAsset,
    downloadAsset,
    deleteAsset,
    createCampaignCSV,
  };
};
