<template>

    <div class="row">
        <div class="col-xs-10 col-xs-offset-1 admin-panel">

            <div class="row">
                <div class="col-xs-12">
                    <div class="ui-header">
                        <h3 class="title">Upload Data</h3>
                        <img title="refresh" :class="{ 'reload-image': true, 'spin-image': loadingData }"
                            @click="fetchData" :src="reloadImg" width="22" height="17">
                    </div>
                    <p class="text-info">Historical upload data from the last two months</p>
                </div>
            </div>

            <div class="row term-container">
                <div class="col-xs-10 col-sm-6">
                    <input type="text" v-model="filterTerm" class="form-control"
                        placeholder="Filter by vendor name, email, brand, filename or style number" />
                </div>
            </div>

            <div class="row btn-container">
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.PI)" value="PI"
                        @click="filterByType(filterType.PI)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.IMAGE)" value="Images"
                        @click="filterByType(filterType.IMAGE)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.VIDEO)" value="Videos"
                        @click="filterByType(filterType.VIDEO)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.LOGO)" value="Logos"
                        @click="filterByType(filterType.LOGO)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.PDF)" value="PDF"
                        @click="filterByType(filterType.PDF)" />
                </div>
                <div class="col-sm-2">
                    <input type="button" :class="btnClass(filterType.CAMPAIGN)" value="Campaign"
                        @click="filterByType(filterType.CAMPAIGN)" />
                </div>
            </div>

            <div class="row">

                <div v-if="loadingData" class="col-xs-12">
                    <h3>Loading...</h3>
                </div>

                <div v-else-if="!filtered.length" class="col-xs-12">
                    <h3>No Results</h3>
                </div>

                <div v-else class="col-xs-12 table-container">
                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>Upload Date</th>
                                <th>Brand</th>
                                <th>Filename</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Notes</th>
                                <th v-if="isStyleDisplayed">
                                    Style</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="upload in filtered" :key="upload.id"
                                :class="{ 'failed-upload': upload.status === 'Failed' }">
                                <td>{{ upload.uploadDate }}</td>
                                <td>{{ upload.brand }}</td>
                                <td>{{ upload.filename }}</td>
                                <td>{{ upload.name }}</td>
                                <td><a :href="'mailto:' + upload.email">{{ upload.email }}</a></td>
                                <td>{{ upload.notes }}</td>
                                <td v-if="isStyleDisplayed">
                                    {{ upload.styleId }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from 'vuex';
import reloadImg from "../../../resources/static/img/reload.png";
import Util from "../../util/util";
import filterType from "../../data/UploadTypes";
import { useAdminApi } from "../../composables/admin";
import Config from "../../data/AdminConfig";

export default {
    name: "upload-data",
    setup() {
        const { getUploadedAssets } = useAdminApi();
        const $store = useStore();
        const uploadData = ref([]);
        const selectedType = ref(filterType.PI);
        const filterTerm = ref("");
        const loadingData = ref(true);

        const filtered = computed(() => {
            const filterAssets = uploadData.value;
            const term = filterTerm.value;
            const selType = selectedType.value;
            const isCampaign = selType.name === "CAMPAIGN";

            return filterAssets
                .filter(upload => Util.isEmpty(term) || upload.filterTerm.includes(term.toLowerCase()))
                .filter(upload => (isCampaign) ? Config.CAMPAIGN_TYPES.includes(upload.type) : upload.type.includes(selType.name));
        });

        const isStyleDisplayed = computed(() => selectedType.value.name === filterType.VIDEO.name || selectedType.value.name === filterType.PDF.name);

        const btnClass = (type) => ({
            'form-control': true,
            'admin-btn': true,
            'btn-selected': selectedType.value.name === type.name
        });

        const filterByType = (type) => {
            selectedType.value = type;
        };

        const addMessage = (message) => {
            $store.commit("addMessage", message);
        };

        const fetchData = async () => {
            loadingData.value = true;
            try {
                const uploads = await getUploadedAssets();
                uploadData.value = uploads;
                loadingData.value = false;
            } catch (error) {
                addMessage("Error fetching asset uploaded data");
                loadingData.value = false;
            }
        };

        onMounted(() => {
            fetchData();
        });

        return {
            reloadImg,
            uploadData,
            filterType,
            selectedType,
            filterTerm,
            loadingData,
            filtered,
            isStyleDisplayed,
            btnClass,
            filterByType,
            fetchData,
        };
    },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/base/vars";
@import "../../../scss/base/mixins";

.term-container {
    margin-top: 20px;
}

.btn-container {
    margin: 20px 0;

    .admin-btn {
        margin: 0 auto;

        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    .btn-selected {
        color: black;
        background-color: #ffa450;
    }
}

.table-container {
    height: auto;
    max-height: 800px;
    overflow: auto;

    table {

        thead {
            background-color: $mainOffsetColor;
            font-weight: bold;

            th {
                height: 50px;
            }
        }

        tbody {
            background-color: white;
        }
    }

    .failed-upload {
        background-color: rgba(255, 71, 55, 0.83);
        color: white;
    }
}
</style>