<template>
  <div :class="defaultClass">
    <div v-if="showTitle" class="ui-header">
      <span>{{ title }}</span>
    </div>
    <slot v-if="hideTitle" name="header"/>
    <div class="ui-header" v-show="showFileInput">
      <input
          type="file"
          ref="selector"
          class="cdr-input-file-campaign"
          :multiple="defaultMultiple"
          :disabled="disabled"
          @change="selectFiles"
      />
    </div>
    <div :class="dragAndDropClass"
         @dragover="onDragOver"
         @dragenter="onDragEnter"
         @dragleave="onDragLeave"
         @drop="onDrop">
      <div v-if="showDropdownMessage" class="ui-container">
        <span>{{ dropdownMessage }}</span>
      </div>
      <slot v-if="hideDropdownMessage" name="dropdown"/>
    </div>
    <div v-if="showButtonsPanel" class="ui-buttons">
      <cdr-button
        type="button"
        modifier="secondary"
        class="ui-button"
        @click="showFileDialog">
        <template #icon-left>
          <icon-brand-rei-ice-axes inherit-color/>
        </template>
        Add assets
      </cdr-button>
    </div>
    <slot v-if="hideButtonsPanel" name="footer"/>
    <div class="ui-panel-message" v-if="containsError">
      <span class="cdr-icon">
        <icon-error-stroke inherit-color size="small" />
      </span>
      <slot name="error" />
    </div>
  </div>
</template>

<script>
import {ref, computed} from 'vue';
import {CdrButton, IconErrorStroke, IconBrandReiIceAxes} from '@rei/cedar';
import Util from '../../util/util';

export default {
  name:  'campaign-upload-input',
  components: {
    CdrButton,
    IconBrandReiIceAxes,
    IconErrorStroke,
  },
  props: {
    supportedFileTypes: {
      type:     Array,
      required: false,
    },
    baseClass:          {
      type:     String,
      required: false,
    },
    title:              {
      type:     String,
      required: false,
    },
    dropdownMessage:    {
      type:     String,
      required: false,
    },
    error: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    disabled: {
      type:     Boolean,
      required: false,
      default:  false,
    },
    multiple:           {
      type:     Boolean,
      required: false,
      default:  false,
    },
    hideInput:          {
      type:     Boolean,
      required: false,
      default:  false,
    },
    hideButtons:        {
      type:     Boolean,
      required: false,
    },
  },
  setup ( props, { emit } ) {
    const selector = ref( null );
    const dragAndDropClass = ref( 'ui-dragdrop-zone' );

    const defaultClass = computed( () => {
      let finalClass = 'campaign-upload-media';
      if ( !Util.isEmpty( props.baseClass ) ) {
        finalClass += props.baseClass;
      }
      if ( props.error ) {
        finalClass += ' error';
      }
      return finalClass;
    } );

    const defaultMultiple = computed( () => {
      return props.multiple && 'multiple';
    } );

    const showTitle = computed( () => {
      return !Util.isEmpty( props.title );
    } );

    const hideTitle = computed( () => {
      return Util.isEmpty( props.title );
    } );

    const showDropdownMessage = computed( () => {
      return !Util.isEmpty( props.dropdownMessage );
    } );

    const hideDropdownMessage = computed( () => {
      return Util.isEmpty( props.dropdownMessage );
    } );

    const showButtonsPanel = computed( () => {
      return !props.hideButtons;
    } );

    const hideButtonsPanel = computed( () => {
      return props.hideButtons;
    } );

    const showFileInput = computed( () => {
      return !props.hideInput;
    } );

    const containsError = computed(() => props.error);

    const selectFiles = ( $event ) => {
      const data = $event.target.files || $event.dataTransfer.files || [];
      const files = [...data];
      const stypes = props.supportedFileTypes || [];
      const validatedFiles = files.map( ( file ) => {
        const isValid = stypes.length === 0 || stypes.includes( file.type );
        const error = !isValid;
        const message = error ? 'This file is not supported' : undefined;
        return {
          file,
          error,
          message,
        };
      } );
      const eventData = {
        files:  validatedFiles.filter( ( media ) => !media.error ).map( ( media ) => media.file ),
        errors: validatedFiles.filter( ( media ) => media.error ).map( ( media ) => ({
          name:    media.file.name,
          error:   media.error,
          message: media.message,
        }) ),
      };
      selector.value.value = '';
      emit( 'selectedFiles', eventData );
    };

    const changeLookAndFeel = ( type ) => {
      dragAndDropClass.value = (type === 'drag-over') ?
          'ui-dragdrop-zone-over' : 'ui-dragdrop-zone';
    };

    const onDragOver = ( event ) => {
      changeLookAndFeel( 'drag-over' );
      event.preventDefault();
    };

    const onDragEnter = () => {
      changeLookAndFeel( 'drag-enter' );
    };

    const onDragLeave = () => {
      changeLookAndFeel( 'drag-leave' );
    };

    const onDrop = ( event ) => {
      changeLookAndFeel( 'drop' );
      event.preventDefault();
      selectFiles( event );
    };

    const showFileDialog = () => {
      selector.value.click();
    };

    return {
      defaultClass,
      defaultMultiple,
      showTitle,
      hideTitle,
      showDropdownMessage,
      hideDropdownMessage,
      showButtonsPanel,
      hideButtonsPanel,
      containsError,
      showFileInput,
      selectFiles,
      dragAndDropClass,
      selector,
      changeLookAndFeel,
      onDragOver,
      onDragEnter,
      onDragLeave,
      onDrop,
      showFileDialog,
    };
  },
}
</script>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/docsite/scss/cdr-tokens.scss";
@import "@rei/cdr-component-variables/dist/scss/index.scss";

.campaign-upload-media > .ui-header {
  @include cdr-text-subheading-sans-600;
}

.campaign-upload-media > .ui-buttons,
.campaign-upload-media {
  margin-top: $cdr-space-one-x;
}

.error {
  @include cdr-form-error-base-mixin;
  border: 1px solid $cdr-color-border-error;
  border-radius: $cdr-radius-softer !important;
}

.error > .ui-panel-message {
  @include cdr-form-error-base-mixin;
  .cdr-icon {
    @include cdr-form-error-icon-mixin;
  }
}

.campaign-upload-media > .ui-header,
.campaign-upload-media > .ui-buttons {
  margin-bottom: $cdr-space-one-x;
}

.campaign-upload-media > .ui-buttons > .ui-button {
  display: flex;
  align-items: center;
}

.campaign-upload-media > .ui-buttons {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
}

.campaign-upload-media > div.ui-dragdrop-zone > div.ui-container,
.campaign-upload-media > div.ui-dragdrop-zone-over > div.ui-container {
  @include cdr-text-heading-sans-300;
  display: flex;
  height: 73px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-weight: 500;
  color: $cdr-color-text-secondary;
}

.campaign-upload-media > div.ui-dragdrop-zone > div.ui-container {
  background-color: #f4f2ed;
}

.campaign-upload-media > div.ui-dragdrop-zone-over > div.ui-container {
  background-color: $cdr-color-background-message-default-01;
  border: 1px dashed $cdr-color-border-success;
}

.cdr-input-file-campaign {
  @include cdr-input-base-mixin;
  
  & {
    cursor: pointer;
  }
}
</style>