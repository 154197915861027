<template>
  <cdr-table responsive>
    <thead>
      <tr>
        <th id="asset" scope="col">Asset</th>
        <th id="sku" scope="col">REI SKU</th>
        <th id="casemark" scope="col">Casemark</th>
        <th id="color" scope="col">Color</th>
        <th id="size" scope="col">Size</th>
        <th id="photographer" scope="col">Photographer</th>
        <th id="actions" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <campaign-grid-row
        v-for="(id, index) in Object.keys(mediaRows)"
        :key="id"
        :id="id"
        :row-index="index"
        :media-data="mediaRows[id]"
        :editable="isEditable"
        :loading-row="mediaRows[id].loading"
        @row-changed="changeRowGridHandle"
        @row-deleted="deleteRowGridHandle"
      />
    </tbody>
  </cdr-table>
</template>

<script>
import { ref, toRef, computed, onUnmounted } from "vue";
import { CdrTable } from "@rei/cedar";
import CampaignGridRow from "./CampaignGridRow.vue";

export default {
  name: "campaign-grid-table",
  components: {
    CdrTable,
    CampaignGridRow,
  },
  props: {
    mediaRows: {
      type: Object,
      required: false,
      default: {},
    },
  },
  setup(props, { emit }) {
    const mediaRows = toRef(props, "mediaRows");
    const edit = ref("on");

    const isEditable = computed(() => {
      return edit.value === "on";
    });

    const deleteRowGridHandle = (event) => {
      const { index, data } = event;
      const ids = Object.keys(mediaRows.value);
      const info = { id: ids[index], index, data };
      emit("gridRowDeleted", info);
    };

    const changeRowGridHandle = (event) => {
      emit("gridRowsChanged", event);
    };

    onUnmounted(() => {
      emit("gridRowsChanged", props.mediaRows);
    });

    return {
      edit,
      mediaRows,
      deleteRowGridHandle,
      changeRowGridHandle,
      isEditable,
    };
  },
};
</script>