<template>
  <div :class="defaultClass" :id="getContainerId">
    <div v-if="containTitle" class="ui-header">
      <span>{{ title }}</span>
      <span v-if="isRequired">*</span>
    </div>
    <slot v-else name="header"/>
    <cdr-form-group
        :id="getFormGroupId"
        :required="isRequiredGroup"
        :error="containsError"
        :disabled="disabled"
    >
      <template #label>
        <slot name="helper"/>
      </template>
      <template v-if="containsError" #error>
        <span :id="getErrorId">
          {{ error }}
        </span>
      </template>
      <slot name="info"/>
      <cdr-radio
          v-if="!containTooltip"
          v-for="option in options"
          :key="id + '-' + option.value"
          :id="id + '-' + option.value"
          :name="name"
          :custom-value="option.value"
          :background="background"
          :aria-describedby="getToolTip(option.value)"
          :checked="isOptionChecked(option.value)"
          @input="changeInputValue"
      >{{ option.label }}
      </cdr-radio>

      <cdr-tooltip v-else
                   v-for="option in options"
                   :key="'tp-' + option.value"
                   :id="'tp-' + option.value"
                   position="right">
        <template #trigger>
          <cdr-radio
              :name="name"
              :id="id + '-' + option.value"
              :custom-value="option.value"
              :background="background"
              :aria-describedby="getToolTip(option.value)"
              :checked="isOptionChecked(option.value)"
              @input="changeInputValue"
          >{{ option.label }}
          </cdr-radio>
        </template>
        <div>
          {{ option.tooltip }}
        </div>
      </cdr-tooltip>
    </cdr-form-group>
  </div>
</template>

<script>
import { computed } from 'vue';
import { CdrRadio, CdrFormGroup, CdrTooltip } from '@rei/cedar';
import Util from '../../util/util';

export default {
  name: 'campaign-radio-group',
  components: {
    CdrRadio,
    CdrFormGroup,
    CdrTooltip,
  },
  props: {
    modelValue: {
      type: [String, null],
      required: true,
      default: null,
    },
    background: {
      type: String,
      required: false,
      default: 'primary',
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    baseClass: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const defaultClass = computed(() => props.baseClass || 'campaign-radio-button-group');

    const getErrorId = computed(() => Util.isNullValue(props.name) ? 'field-error' : (props.name + '-error'));

    const getContainerId = computed(() => Util.isNullValue(props.id) ? '' : (props.id + '-container'));

    const getFormGroupId = computed(() => Util.isNullValue(props.id) ? '' : props.id);

    const getToolTip = (optionValue) => {
      return optionValue + '-tt';
    };

    const isOptionChecked = (optionValue) => {
      const modelValue = props.modelValue;
      return !Util.isNullValue(modelValue) && modelValue === optionValue;
    };

    const changeInputValue = (event) => {
      const previousValue = props.modelValue;
      const currentValue = event.target.value;
      if (previousValue !== currentValue) {
        emit('update:modelValue', currentValue);
        emit('changed', { name: props.name, value: currentValue });
      }
    };

    const containTooltip = computed(() => {
      const options = props.options || [];
      const items = options.filter((option) => option.tooltip);
      const empty = !items || items.length === 0;
      return !empty;
    });

    const containTitle = computed(() => {
      return props.title && props.title.length > 0;
    });

    const containsError = computed(() => {
      const errorMessage = props.error;
      return !Util.isEmpty(errorMessage);
    });

    const isRequired = computed(() => {
      return props.required;
    });

    const isRequiredGroup = computed(() => {
      return Util.isEmpty(props.title) && props.required;
    });

    return {
      defaultClass,
      getContainerId,
      getFormGroupId,
      getErrorId,
      getToolTip,
      isOptionChecked,
      changeInputValue,
      containTooltip,
      containsError,
      containTitle,
      isRequired,
      isRequiredGroup,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/docsite/scss/cdr-tokens.scss";

.campaign-radio-button-group {
  @include cdr-text-body-300;
}

.campaign-radio-button-group > .ui-header {
  @include cdr-text-subheading-sans-600;
}

.campaign-radio-button-group > fieldset > legend {
  border-bottom-color: transparent !important;
}

.campaign-radio-button-group > fieldset > legend > .ui-message {
  @include cdr-text-body-300;
}

.campaign-radio-button-group > fieldset > legend > .ui-message-bold {
  @include cdr-text-body-300;
  font-weight: 500 !important;
}

.campaign-radio-button-group {
  margin-top: $cdr-space-one-x;
}

.campaign-radio-button-group > .ui-header {
  margin-bottom: $cdr-space-one-x;
}

</style>