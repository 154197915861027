<template>

    <div class="admin-container">
        <div class="row">
            <div class="col-xs-10 col-xs-offset-1">
                <h1>Admin</h1>
            </div>
        </div>
        <add-news></add-news>
        <download-assets></download-assets>
        <campaign-metadata></campaign-metadata>
        <pdf-assets></pdf-assets>
        <upload-data></upload-data>
        <upload-resources></upload-resources>
        <upload-templates></upload-templates>
        <archived-assets></archived-assets>
    </div>

</template>

<script>
import AddNews from "./AddNews.vue";
    import DownloadAssets from "./DownloadAssets.vue";
    import CampaignMetadata from "./CampaignMetadata.vue";
    import PdfAssets from "./PdfAssets.vue";
    import UploadData from "./UploadData.vue";
    import UploadResources from "./UploadResources.vue";
    import UploadTemplates from "./UploadTemplates.vue";
    import ArchivedAssets from "./ArchivedAssets.vue";

    export default {
        name: "admin",
        data() {
            return {
            }
        },
        components: {
            AddNews,
            DownloadAssets,
            CampaignMetadata,
            PdfAssets,
            UploadData,
            UploadResources,
            UploadTemplates,
            ArchivedAssets
        }
    }
</script>

<style lang="scss">

    @import "../../../scss/base/mixins";
    @import "../../../scss/base/vars";

    .admin-panel {
        background-color: $mainBgColor;
        font-family: 'Lato', Helvetica, Neue, Roboto, Arial, sans-serif;
        font-size: 125%;
        @include box-shadow;
        margin-bottom: 20px;

        .title {
            font-weight: bold;
        }

        .admin-btn {
            margin-top: 10px;
            width: 100%;
            min-width: 90px;
            max-width: 150px;
            background-color: $adminOrange;
            color: white;
            font-weight: bold;
        }
    }

    .reload-image {
        &:hover {
            cursor: pointer;
        }
    }

</style>