import { v4 as uuidv4 } from 'uuid';

export default {

    validateEmail(email) {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !this.isEmpty(email) && regex.test( email.toLowerCase() );
    },

    indexOfIgnoreCase ( array, itemToSearch ) {
        return array.findIndex( item => itemToSearch.toLowerCase() === item.toLowerCase() );
    },

    removeUnderscore ( value ) {
        if ( !value ) return '';
        return value.match( /_/g ) ? value.replace( /_/g, " " ) : value;
    },

    removeXlsx ( value ) {
        return value.includes( ".xlsx" ) ? value.replace( ".xlsx", "" ) : value;
    },

    isNullValue ( value ) {
        return value === undefined || value === null;
    },

    isEmpty(value) {
        return (this.isNullValue( value ) || (typeof value === "string" && value.trim().length === 0));
    },

    isEmptyArray ( value ) {
        return this.isNullValue( value ) || (Array.isArray( value ) && value.length === 0);
    },

    toSentenceCase ( text ) {
        if ( this.isEmpty( text ) ) return undefined;
        return text.charAt( 0 ).toUpperCase() + text.slice( 1 ).toLowerCase();
    },

    transformStringToCase ( text, caseText ) {
        if ( this.isEmpty( text ) ) return undefined;
        switch ( caseText ) {
            case 'uppercase':
                return text.toUpperCase();
            case 'lowercase':
                return text.toLowerCase();
            case 'sentencecase':
            case 'titlecase': {
                const phrases = text.split( ' ' );
                const output = phrases.map( ( phrase, index ) => {
                    if ( caseText === 'titlecase' || index === 0 ) {
                        return this.toSentenceCase( phrase );
                    } else {
                        return phrase;
                    }
                } );
                return output.join( ' ' );
            }
        }
    },

    delay ( ms ) {
        return new Promise( ( resolved ) => {
            setTimeout( resolved, ms );
        } );
    },

    generateRowUUID () {
        return uuidv4();
    },
};