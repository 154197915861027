import { createApp } from 'vue';
import appRouter from '../util/routes';
import Util from '../util/util';
import routeNames from '../data/routeNames';
import store from '../store';
import axios from "axios";
import Cookies from 'js-cookie';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { faPiedPiperAlt } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faVideo, faCamera, faTable, faPiedPiperAlt, faFilePdf, faChevronCircleUp, faTrashAlt, faArrowCircleDown, faSync);

store.commit("setRole", reiVendorInitRole);
store.commit("setUsername", reiVendorInitUsername);

appRouter.beforeEach((to, from, next) => {

  let role = store.state.role;

  //scroll to top on route change
  window.scrollTo(0, 0);

  if (to.name === routeNames.LOGIN) {

    if (role) {
      //user is already logged in, redirect to main page
      if (role === routeNames.ADMIN) {
        next({ name : routeNames.ADMIN });
      } else {
        next({ name : routeNames.HOME });
      }
    } else {
      next();
    }

  } else if (to.name === routeNames.ADMIN) {

    //check if admin user, in not redirect to login
    if (role === routeNames.ADMIN) {
      next();
    } else {
      next({ name : routeNames.LOGIN });
    }

  } else if (to.name === routeNames.CAMPAIGN || to.name === routeNames.CAMPAIGN_EXPIRED || to.name === routeNames.CAMPAIGN_SUCCESS) {
    const campaignTokenValue = Cookies.get('campaign-token');
    // it's to make sure only this redirection the user can see this page...
    const goToSuccessPage = from.name === routeNames.CAMPAIGN && to.CAMPAIGN_SUCCESS;
    if (!Util.isEmpty(campaignTokenValue) || goToSuccessPage) {
      next();
    } else {
      next({ name : routeNames.CAMPAIGN_EXPIRED });
    }
  } else {

    //check if user is logged in, if not redirect to login
    if (role) {
      next();
    } else {
      next({ name : routeNames.LOGIN });
    }
  }
});

/**
 * Get pageData from modelData script
 * @returns page data object
 */
function getClientPageData(pageDataId) {
  const id = pageDataId || 'modelData';
  const model = window.document.getElementById(id);
  const pageData = model === null ? {} : JSON.parse(model.innerHTML);
  return { pageData };
}

/**
 * Mounts the Vue page component to the specified app entry
 * @param {Object} page the Vue page component
 * @param {Array} plugins an array of plugins to use, if needed
 * @returns a Vue app
 */
export default function init(page, plugins = []) {
  const { pageData: props } = getClientPageData();
  const app = createApp(page, props);

  app.config.globalProperties.$http = axios;
  app.config.globalProperties.$props = props;

  if (plugins.length > 0) {
    plugins.forEach((plugin) => {
      app.use(plugin);
    });
  }

  app.component('font-awesome-icon', FontAwesomeIcon);
  app.use(appRouter);
  app.use(store);

  app.mount('#app');
  return app;
}
