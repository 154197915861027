<script>
import { CdrContainer, CdrButton } from "@rei/cedar";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";
import Util from "../../util/util";
import routeNames from "../../data/routeNames";

export default {
  name: "campaign-success-entry",
  components: {
    CdrContainer,
    CdrButton,
  },
  setup() {
    const router = useRouter();

    const onClickHandler = () => {
      const token = Cookies.get("campaign-token");
      if (Util.isEmpty(token)) {
        router.push({
          name: routeNames.CAMPAIGN_EXPIRED,
        });
        return;
      }
      window.location.href = `/rs/campaign/loadCampaign/${token}`;
    };
    return {
      onClickHandler,
    };
  },
};
</script>

<template>
  <div class="row">
    <div class="col-sm-9 col-sm-offset-1">
      <div class="campaign-success-box">
        <div class="ui-title">Assets Submission Completed!</div>
        <div class="ui-summary">
          Thank you for submitting your assets to REI.
        </div>
        <div class="ui-summary">
          If you have more assets to submit click on "Upload more" button.
        </div>
        <div class="ui-summary">
          <cdr-button
            type="button"
            id="media-right-repeat-submission"
            @click="onClickHandler"
          >
            Upload more
          </cdr-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@rei/cdr-tokens/dist/docsite/scss/cdr-tokens.scss";

.campaign-success-box {
  width: 100%;
  display: grid;
}

.campaign-success-box > .ui-title,
.campaign-success-box > .ui-summary {
  margin: $cdr-space-inset-half-x-stretch;
}

.campaign-success-box > .ui-title {
  @include cdr-text-heading-serif-600;
}

.campaign-success-box > .ui-summary {
  @include cdr-text-body-300;
}
</style>
